import { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { WaxContext } from 'wax-prosemirror-core';
import { HuePicker, SketchPicker } from 'react-color';
import styled from 'styled-components';
import { AlignCenterOutlined, AlignLeftOutlined, AlignRightOutlined, FontColorsOutlined, FontSizeOutlined, LineHeightOutlined } from "@ant-design/icons";
import { toggleMark } from 'prosemirror-commands';


const Wrapper = styled.div`
  font-size: 0;
  position: relative;
  z-index: 2;
  padding:0px 3px;
`;

const DropWrapper = styled.div`
  background: white;
  margin-top: 20px;
  position: absolute;
  top: 32px;
  width: max-content;
  color:black;
`;

const Highlighter = styled.div`
  cursor: pointer;
  display: inline-grid;
  height: 20px;
  margin: 5px;
  margin-top:10px;
  min-width: 20px;
`;

const HighlighterStyle = styled.div`
  cursor: pointer;
  padding:2px 5px;
  margin-top:10px;
  height:1px;
`;

const HighlighterStyleSize = styled.div`
cursor: pointer;
padding:2px 5px;
margin-top:10px;
height:20px;
display:flex;
flext-direction:row;
`;

const LineItems = styled.div`
 display:flex;
 flext-direction:column;
`;

const TextHighlightComponent = styled.div`
     color:red;
     display: flex;
     flex-direction: column;
     width: 80px;
     height:fit-content;
     padding: 10px 10px 0px;
     box-sizing: initial;
     background: rgb(255, 255, 255);
     border-radius: 4px;
     box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 0px 1px, rgba(0, 0, 0, 0.15) 0px 8px 16px;
`;

const TextAlignmentComponent = styled.div`
     color:red;
     display: flex;
     flex-direction: row;
     height:35px;
     padding: 10px 10px 0px;
     box-sizing: initial;
     background: rgb(255, 255, 255);
     border-radius: 4px;
     box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 0px 1px, rgba(0, 0, 0, 0.15) 0px 8px 16px;
`;

const StyledTextSize = styled.span`
  color: black;
  text-align: left;
  font-size:14px;
  margin: 4px 5px;
  cursor:pointer;
`;

const StyledText = styled.span`
  color: black;
  text-align: left;
  font-size:20px;
  margin: 1px 5px;
  cursor:pointer;
`;

const TextTypoSection = () => {
     const {
          app,
          pmViews: { main },
          activeViewId,
          activeView,
          placeholderPlugin
     } = useContext(WaxContext);
     const [colorLabel, SetcolorLabel] = useState("black");
     const [hideColor, SethideColor] = useState(false);
     const [hidelineHeight, SethidelineHeight] = useState(false);
     const [sizeText, SethideSize] = useState(false);
     const [colorBar, SetcolorBar] = useState("black");
     const [fontFamily, setFontFamily] = useState('Arial');

     const FontFamilyList = [
          { label: "Arial", value: "Arial, sans-serif" },
          { label: "Courier New", value: "'Courier New', Courier, monospace" },
          { label: "Georgia", value: "Georgia, serif" },
          { label: "Times New Roman", value: "'Times New Roman', Times, serif" },
          { label: "Verdana", value: "Verdana, sans-serif" },
          { label: "Tahoma", value: "Tahoma, sans-serif" },
          { label: "Trebuchet MS", value: "'Trebuchet MS', sans-serif" },
          { label: "Lucida Console", value: "'Lucida Console', Monaco, monospace" },
          { label: "Comic Sans MS", value: "'Comic Sans MS', cursive, sans-serif" },
          { label: "Impact", value: "Impact, Charcoal, sans-serif" },
     ];
     const FontSizes = [
          { label: "6px", value: "6px" },
          { label: "8px", value: "8px" },
          { label: "10px", value: "10px" },
          { label: "12px", value: "12px" },
          { label: "14px", value: "14px" },
          { label: "16px", value: "16px" },
          { label: "18px", value: "18px" },
          { label: "20px", value: "20px" },
          { label: "22px", value: "22px" },
          { label: "24px", value: "24px" },
          { label: "26px", value: "26px" },
          { label: "28px", value: "28px" },
          { label: "30px", value: "30px" },
          { label: "32px", value: "32px" },
          { label: "34px", value: "34px" },
          { label: "36px", value: "36px" },
          { label: "38px", value: "38px" },
          { label: "40px", value: "40px" },
          { label: "42px", value: "42px" },
          { label: "44px", value: "44px" },
          { label: "46px", value: "46px" },
          { label: "48px", value: "48px" },
          { label: "50px", value: "50px" },
          { label: "52px", value: "52px" },
          { label: "54px", value: "54px" },
          { label: "56px", value: "56px" },
          { label: "58px", value: "58px" },
          { label: "60px", value: "60px" },
     ];
     const LineSize = [1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6]
     const [fontSize, setFontSize] = useState(16);


     /**
 * remove current span
 * @returns 
 */
     const toogleRemoveColor = () => {
          const { state, dispatch } = activeView;
          const { schema } = state;

          const markType = schema.marks.colorText;
          if (!markType) return false;


          // Apply or update the font size mark on the selected text
          toggleMark(markType, { 'font-size': '' })(state, dispatch);
     }
     /**
      * on select color change font color
      * @param {*} colorCode 
      */
     const ColorAction = (colorCode) => {
          toogleRemoveColor();
          SetcolorLabel(colorCode)
          const { state, dispatch } = activeView;
          let color = 'color:' + colorCode;
          const markType = state.schema.marks.colorText;
          const mark = markType.create({ color });
          console.log(state.selection.from, state.selection.to)
          activeView.dispatch(state.tr.addMark(state.selection.from, state.selection.to, markType.create({ style: color })));
          SethideColor(!hideColor)
     }


     /**
    * remove current span
    * @returns 
    */
     const toogleRemoveFamily = () => {
          const { state, dispatch } = activeView;
          const { schema } = state;

          const markType = schema.marks.fontFamilyText;
          if (!markType) return false;

          // Apply or update the font size mark on the selected text
          toggleMark(markType, {})(state, dispatch);
     }

     /**
      * on Change font family
      * @param {*} fontFamily 
      */
     const FontfamilyAction = (fontFamilyval) => {
          toogleRemoveFamily();
          setFontFamily(fontFamily)
          SethideSize(false);
          SethidelineHeight(false);
          SethideColor(false);
          // SethideColor(!hideColor)
          const { state, dispatch } = activeView;
          let font = 'font-family:' + fontFamilyval;
          const markType = state.schema.marks.fontFamilyText;
          console.log(state.selection.from, state.selection.to)
          activeView.dispatch(state.tr.addMark(state.selection.from, state.selection.to, markType.create({ style: font })));

     }

     /**
    * remove current span for font size
    * @returns 
    */
     const toogleRemoveSize = () => {
          const { state, dispatch } = activeView;
          const { schema } = state;

          const markType = schema.marks.fontSize;
          if (!markType) return false;

          // Create a new mark with the desired font size
          const newMark = markType.create({ 'font-size': fontSize });

          // Apply or update the font size mark on the selected text
          toggleMark(markType, { 'font-size': fontSize })(state, dispatch);
     }

     /**
     * on Change font size
     * @param {*} fontSize 
     */
     const FontsizeAction = (fontSizeVal) => {
          toogleRemoveSize();
          const { state, dispatch } = activeView;
          const markType = state.schema.marks.fontSize;
          console.log(state.schema)
          let font = 'font-size:' + fontSizeVal + 'px';
          console.log(font)
          activeView.dispatch(state.tr.addMark(state.selection.from, state.selection.to, markType.create({ style: font })));
     }

     /**
 * remove current span for font line height
 * @returns 
 */
     const toogleRemoveLine = () => {
          const { state, dispatch } = activeView;
          const { schema } = state;

          const markType = schema.marks.lineHeightText;
          if (!markType) return false;

          // Apply or update the font size mark on the selected text
          toggleMark(markType, {})(state, dispatch);
     }

     /**
      * line height
      */
     const LineheightAction = (line) => {
          toogleRemoveLine();
          // SethideColor(!hideColor)
          const { state, dispatch } = activeView;
          let font = 'line-height:' + line;
          const markType = state.schema.marks.lineHeightText;
          console.log(state.selection.from, state.selection.to)
          activeView.dispatch(state.tr.addMark(state.selection.from, state.selection.to, markType.create({ style: font })));
          SethidelineHeight(!hidelineHeight)
     }

     /**
     * remove current span for font line height
     * @returns 
     */
     const toogleRemoveAlign = () => {
          const { state, dispatch } = activeView;
          const { schema } = state;

          const markType = schema.marks.textAlignment;
          if (!markType) return false;

          // Apply or update the font size mark on the selected text
          toggleMark(markType, {})(state, dispatch);
     }

     /**
      * text alignment
      */
     const AlignmentAction = (data) => {
          toogleRemoveAlign();
          const { state, dispatch } = activeView;
          let font = 'text-align:' + data;
          const markType = state.schema.marks.textAlignment;
          console.log(state.selection.from, state.selection.to)
          activeView.dispatch(state.tr.addMark(state.selection.from, state.selection.to, markType.create({ style: font })));
          SethideSize(!sizeText)
     }


  

     return (
          <>

               <div className="font-family-container" style={{ display: 'flex' }}>
                    <HighlighterStyle title="font family">
                         <select style={{ width: "100px" }} onChange={(e) => FontfamilyAction(e.target.value)}>
                              {
                                   FontFamilyList.map(item => {
                                        return <option value={item.value}>{item.label}</option>

                                   })
                              }
                         </select>
                    </HighlighterStyle>

               </div>
               <div className="font-family-container" style={{ display: 'flex', flexDirection: "row" }}>
                    <HighlighterStyleSize title="font size">
                         <span style={{ fontSize: "20px", padding: "0px 5px" }} onClick={() => { FontsizeAction(fontSize - 1); setFontSize(fontSize - 1) }}>-</span>
                         <input style={{ width: "22px", height: "22px" }} value={fontSize} onChange={(e) => { FontsizeAction(e.target.value); setFontSize(e.target.value) }} />
                         <span style={{ fontSize: "20px", padding: "0px 5px" }} onClick={() => { FontsizeAction(fontSize + 1); setFontSize(fontSize + 1) }}>+</span>
                    </HighlighterStyleSize>
               </div>
               <Wrapper>
                    <Highlighter title="Align">
                         <AlignLeftOutlined onClick={() => { SethideSize(!sizeText); SethidelineHeight(false); SethideColor(false) }} />
                    </Highlighter>
                    <DropWrapper>
                         {
                              sizeText &&
                              <TextAlignmentComponent>
                                   <StyledText title="Left" onClick={() => AlignmentAction('left')}>
                                        <AlignLeftOutlined />
                                   </StyledText>
                                   <StyledText title="Center" onClick={() => AlignmentAction('center')}>
                                        <AlignCenterOutlined />
                                   </StyledText>
                                   <StyledText title="Right" onClick={() => AlignmentAction('right')}>
                                        <AlignRightOutlined />
                                   </StyledText>
                              </TextAlignmentComponent>
                         }
                    </DropWrapper>
               </Wrapper>
               <Wrapper>
                    <Highlighter title="line height">
                         <LineHeightOutlined onClick={() => { SethidelineHeight(!hidelineHeight); SethideSize(false); SethideColor(false) }} />
                    </Highlighter>
                    <DropWrapper>
                         {
                              hidelineHeight &&
                              <TextHighlightComponent>
                                   {
                                        LineSize.map(item => {
                                             return <StyledTextSize onClick={() => LineheightAction(item)}>{item}</StyledTextSize>
                                        })
                                   }
                              </TextHighlightComponent>

                         }
                    </DropWrapper>
               </Wrapper>
               <Wrapper>
                    <Highlighter title="font color">
                         <FontColorsOutlined onClick={() => { SethideColor(!hideColor); SethidelineHeight(false); SethideSize(false); SethidelineHeight(false) }} />
                    </Highlighter>
                    <DropWrapper>
                         {
                              hideColor &&
                              <div className="color-picker">
                                   <SketchPicker
                                        color={colorLabel}
                                        onChange={(e) => ColorAction(e.hex)}
                                   />
                              </div>
                         }
                    </DropWrapper>
               </Wrapper>
          </>
     )
}

export default TextTypoSection;