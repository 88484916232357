import { cloneDeep, set } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { Col, Row } from "reactstrap";
import { setLoading } from "../../store/slices/auth";
import { CustomButton, showToaster, toasterTypes } from "../../widgets";
import { FieldItem } from "../../widgets/fields";
import useFieldItem from "../../widgets/fields/UseFieldItem";
import ModalComponent from "../common/Modal/modal";
import {
  createPlan,
  createTask,
  deleteTask,
  getPlanTemplates,
  getPlanTemplateTasks,
  updatePlan,
  updateTask,
} from "./apiController";
import { CreateFormElements } from "./PlannerFormElements";
import { DeleteFilled } from "@ant-design/icons";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

interface Task {
  id: number;
  orderId: number;
  taskname: string;
  taskdescription: string;
  plannedduration: number;
  planneddurationunits: any; // Replace 'any' with the actual type if known
  planneddurationunitsmanual: { value: string; label: string };
  plannedstartdate: Date | null;
  plannedenddate: Date | null;
}

export default function CreateForm(props) {
  const dispatch = useDispatch();
  const initialValues = useMemo(
    () =>
      props.previousState
        ? props.previousState
        : {
          name: "",
          description: "",
          startdate: null,
          enddate: null,
          planradiobuttons: "a"
        },
    [props.previousState]
  );

  const [plannedDurationError, setPlannedDurationError] = useState<any>(false);
  const [plannedDurationUnitState, setPlannedDurationUnitState] = useState<any>({ value: "Percentage", label: "Percentage" });
  const [values, fields, handleChange, { validateValues, updateValue }] =
    useFieldItem(CreateFormElements, initialValues, { onValueChange });
  const [tasks, setTasks] = useState<Task[]>([]);
  const [taskIds, setTaskIds] = useState<any>([]);
  let updatedTaskIds: any = [];
  const [planTemplates, setPlanTemplates] = useState<any>([]);
  const [planTemplateSelectedValue, setPlanTemplateSelectedValue] = useState<any>();
  const [overridePlanEndDate, setOverridePlanEndDate] = useState<Boolean>(false);
  const [showPopup, setShowPopup] = useState(false);
  const [pendingTask, setPendingTask] = useState<any>(null);
  const [isDragDisabledState, setIsDragDisabledState] = useState<boolean>(false);


  useEffect(() => {
    if (props.isEdit) {
      updateValue({
        name: props.formData?.plan_name,
        description: props.formData?.plan_desc,
        startdate: props.formData?.plan_start_date,
        enddate: props.formData?.plan_end_date,
        planradiobuttons: props.formData?.auto_tasks_schedule ? "a" : "m"
      });
      if (props.formData?.tasks) {
        let resTasks: any = props.formData?.tasks;
        let newTasks: any = [];
        resTasks.forEach((task) => {
          newTasks.push({
            id: task.id,
            taskname: task.task_name,
            taskdescription: task.task_desc,
            plannedduration: task.planned_duration,
            planneddurationunits: { value: task.planned_duration_unit, label: task.planned_duration_unit },
            planneddurationunitsmanual: { value: task.planned_duration_unit, label: task.planned_duration_unit },
            plannedstartdate: task.planned_start_date,
            plannedenddate: task.planned_end_date
          })
        })
        setTasks(newTasks);
        setTaskIds(props.formData?.task_ids);
        setPlannedDurationUnitState(newTasks[0].planneddurationunits);
      }

    } else {
      getPlanTemplate();
    }
  }, []);


  async function getPlanTemplate() {
    try {
      dispatch(setLoading(true));
      let response = await getPlanTemplates();
      const transformedTemplates = response.data.map((d) => ({
        value: d.id,
        label: d.template_name,
      }));
      setPlanTemplates(transformedTemplates);
      dispatch(setLoading(false));

    } catch (e: any) {
      dispatch(setLoading(false));
      showToaster(toasterTypes.ERROR, "Failed To fetch Plan Templates");
    }
  }

  /**
   *
   * @param name
   * @param value
   * @param values
   * @param fieldValues
   * @returns
   */
  function onValueChange(name, value, values, fieldValues = null) {
    let resetValue = {};
    console.log(name)
    console.log(value)
    console.log(values)
    console.log("insid value change")
    return [
      {
        ...values,
        ...resetValue,
        [name]: value,
      },
    ];
  }

  // Helper function to convert different units to milliseconds
  const durationToMs = (duration, unit) => {
    switch (unit.toLowerCase()) {
      case 'days':
        return duration * 24 * 60 * 60 * 1000;
      case 'hours':
        return duration * 60 * 60 * 1000;
      case 'weeks':
        return duration * 7 * 24 * 60 * 60 * 1000;
      case 'months':
        return duration * 30 * 24 * 60 * 60 * 1000; // Approximate a month as 30 days
      default:
        throw new Error('Invalid duration unit');
    }
  };

  const handlePopupResponse = (response: boolean) => {
    setOverridePlanEndDate(response);
    setShowPopup(false); // Hide popup after selection

    if (response && pendingTask) {
      // User chose 'Yes', handle overriding the end date
      const { start, totalDurationOfTasks, temp, index } = pendingTask;

      const newEnd= new Date(start.getTime() + totalDurationOfTasks);
      const newEndDate = newEnd.toISOString();
      console.log("new new end",newEndDate)
      console.log("new new values",values)
      updateValue({
        ...values,
        enddate: newEndDate
      });
    } else if (pendingTask) {
      // User chose 'No', reset task duration
      const { temp, index } = pendingTask;
      if (temp && temp[index]?.plannedduration) {
        temp[index].plannedduration = 0;
      }
    }

    // Set the tasks after handling response
    if (pendingTask) setTasks(pendingTask.temp);
    setPendingTask(null); // Clear pending task data
  };

  const changeTasks = (name: string, value: any, index: number) => {
    let temp: any = cloneDeep(tasks);
    console.log(name, value, index);

    if (typeof index === 'number') {
      temp[index][name] = value;
      console.log("added value",value);
    }

    if ((name === "planneddurationunitsmanual" || name === CreateFormElements.plannedDuration.name) && values.planradiobuttons === "a" && (typeof index === 'number')) {
      console.log("changed")
      let planStartDate: any = validDate(values.startdate);
      let planEndDate: any = validDate(values.enddate);

      const start: any = new Date(planStartDate);
      const end: any = new Date(planEndDate);
      const totalDurationMs = end - start;

      let totalDurationOfTasks = 0;
      for (const task of temp) {
        const { plannedduration, planneddurationunitsmanual } = task;
        console.log(plannedduration)
        totalDurationOfTasks += durationToMs(Number(plannedduration), planneddurationunitsmanual.value);
      }
      console.log(totalDurationOfTasks)
      console.log(totalDurationMs)
      if (totalDurationOfTasks > totalDurationMs) {
        // Save the necessary data in pendingTask
        setPendingTask({ start, totalDurationOfTasks, temp, index });

        // Show the popup and wait for user input
        setShowPopup(true);
        return; // Exit the function, handle the rest based on user response
      }
    }
    if ((name === CreateFormElements.plannedDuration.name || name === CreateFormElements.plannedStartDate.name || name === "planneddurationunitsmanual") && values.planradiobuttons === "m" && (typeof index === 'number')) {
      let plannedStartDate: any = validDate(temp[index].plannedstartdate);
      if(plannedStartDate && temp[index].plannedduration && temp[index].planneddurationunitsmanual.value){
        let plannedEndDate: any = getPlannedEndDate(plannedStartDate, temp[index].plannedduration, temp[index].planneddurationunitsmanual.value);
        temp[index].plannedenddate = plannedEndDate;
      }
    }

    console.log("change tasks", temp);
    setTasks(temp);
  };

  function resetPlannedDurationValues(value) {
    if (value && value.value && value.label) {
      setPlannedDurationUnitState(value);

      let resetTasks: any = cloneDeep(tasks);

      for (let task of resetTasks) {
        task.plannedduration = 0;
        task.planneddurationunits = value;
      }
      setTasks(resetTasks);
    }
  }

  const sortTasksByOrderId = (a: any, b: any) => a.orderId - b.orderId;

  function addTask() {
    if (validatePlanDates()) {
      showToaster(toasterTypes.ERROR, "Please enter the Plan Start Date and End Date");
      return;
    }
    let temp: any = cloneDeep(tasks);
    const newTaskId = temp.length > 0 ? Math.max(...temp.map(t => t.id)) + 1 : 1;

    temp.push({
      id: newTaskId,
      taskname: "",
      taskdescription: "",
      plannedduration: 0,
      planneddurationunits: plannedDurationUnitState,
      planneddurationunitsmanual: { value: "Days", label: "Days" },
      plannedstartdate: null,
      plannedenddate: null
    });

    setTasks(temp);
  }
  async function removeTask(id) {
    if (props.isEdit) {
      try {
        dispatch(setLoading(true));
        if(taskIds.includes(id)){
          let response: any = await deleteTask(id);
        }
        dispatch(setLoading(false));
      } catch (e: any) {
        dispatch(setLoading(false));
      }
      setTasks(tasks.filter((item) => item.id != id));
      setTaskIds(taskIds.filter((item) => item.id != id));
    } else {
      setTasks(tasks.filter((item) => item.id != id));
      setTaskIds(taskIds.filter((item) => item.id != id));
    }
  }

  function validTask(task) {
    if (task.taskname === "") {
      return false;
    }
    if (task.taskdescription === "") {
      return false;
    }
    if (task.plannedduration === 0) {
      return false;
    }
    if (values.planradiobuttons === "a" && task.planneddurationunits.value === null) {
      return false;
    }
    if (values.planradiobuttons === "m" && task.planneddurationunitsmanual.value === null) {
      return false;
    }
    if (values.planradiobuttons === "m" && task.plannedstartdate === null) {
      return false;
    }
    return true;
  }

  function validDate(date) {
    let startDate: any = new Date(date);
    if (isNaN(startDate)) {
      return startDate;
    }
    else {
      return startDate.toISOString();
    }
  }

  function validatePlanDates() {
    if (values.startdate === null || values.enddate === null) {
      return true;
    }
    return false;
  }

  function validateTaskNameAndDesc() {
    for (const task of tasks) {
      if (task.taskname === "") {
        return true
      }
      if (task.taskdescription === "") {
        return true
      }
    }
    return false
  }

  function getDurationSum() {
    let durationSum: number = 0;
    tasks.forEach(task => {
      durationSum += Number(task.plannedduration);
    })
    return durationSum;
  }

  function validateTasksPlannedDurationSameUnits() {
    let plannedDurationUnit = plannedDurationUnitState.value;
    let durationSum: any = getDurationSum();
    const startDate: any = new Date(validDate(values.startdate))
    const endDate: any = new Date(validDate(values.enddate))

    const diffInMilliSecs: any = endDate - startDate;


    if (plannedDurationUnit === "Percentage") {
      return durationSum > 100;
    }
    else if (plannedDurationUnit === "Hours") {
      const totalHours = Number(diffInMilliSecs / (1000 * 60 * 60));
      return durationSum > totalHours;
    }
    else if (plannedDurationUnit === "Days") {
      const totalDays = Number(diffInMilliSecs / (1000 * 60 * 60 * 24));

      return durationSum > totalDays;
    }
    else if (plannedDurationUnit === "Weeks") {
      const totalWeeks = Number(diffInMilliSecs / (1000 * 60 * 60 * 24 * 7));

      return durationSum > totalWeeks;
    }
    else if (plannedDurationUnit === "Months") {
      const yearsDiff = endDate.getFullYear() - startDate.getFullYear();
      const monthsDiff = endDate.getMonth() - startDate.getMonth();

      let totalMonthsDiff = yearsDiff * 12 + monthsDiff;

      if (endDate.getDate() < startDate.getDate()) {
        totalMonthsDiff--;
      }

      return durationSum > totalMonthsDiff;
    }
    return false;
  }

  function validateTasksPlannedDurationDifferentUnits() {
    let planStartDate: any = validDate(values.startdate);
    let planEndDate: any = validDate(values.enddate);

    // Parse the plan start and end dates
    const start: any = new Date(planStartDate);
    const end: any = new Date(planEndDate);

    // Calculate the total duration between start and end dates in milliseconds
    const totalDurationMs = end - start;

    // Check if duration of each task exceeds totalduration

    for (const task of tasks) {
      const { plannedduration, planneddurationunitsmanual, plannedstartdate } = task;
      if (values.planradiobuttons === "m") {
        let plannedStartDate: any = validDate(plannedstartdate);
        // Check if plannedStartDate is earlier than planStartDate
        const taskStart: any = new Date(plannedStartDate);
        if (taskStart < start) {
          return true;
        }
      }

      // Check if task duration exceeds total duration
      if (durationToMs(Number(plannedduration), planneddurationunitsmanual.value) > totalDurationMs) {
        return true;
      }
    }

    return false;
  }

  function validateTasksPlannedDurationGreaterThanZero() {
    return tasks.some(task => Number(task.plannedduration) === 0);
  }

  function getPlannedEndDate(startDate, plannedDuration, plannedDurationUnit) {
    if (!startDate) {
      return null
    }
    if (plannedDurationUnit === "Percentage") {
      return null
    }
    let start = new Date(startDate);
    plannedDuration = Number(plannedDuration)
    // Calculate the end date based on the unit
    switch (plannedDurationUnit) {
      case 'Days':
        start.setDate(start.getDate() + plannedDuration);
        break;
      case 'Hours':
        start.setHours(start.getHours() + plannedDuration);
        break;
      case 'Weeks':
        start.setDate(start.getDate() + (plannedDuration * 7));
        break;
      case 'Months':
        start.setMonth(start.getMonth() + plannedDuration);
        break;
      default:
        throw new Error('Invalid planned duration unit');
    }

    // Return the end date in ISO string format
    return start.toISOString();

  }


  async function updateTasks(tasks) {
    for (const task of tasks) {

      if (validTask(task) && taskIds.includes(task.id)) {
        let plannedStartDate: any = validDate(task.plannedstartdate);
        let plannedEndDate: any = getPlannedEndDate(plannedStartDate, task.plannedduration, task.planneddurationunitsmanual.value);

        let taskReqObj: any;
        if (values.planradiobuttons === "m") {
          taskReqObj = {
            "id": task.id,
            "task_name": task.taskname,
            "task_desc": task.taskdescription,
            "planned_duration": Number(task.plannedduration),
            "planned_duration_unit": task.planneddurationunitsmanual.value,
            "planned_start_date": plannedStartDate,
            "planned_end_date": plannedEndDate,
          };
        } else if (values.planradiobuttons === "a") {
          taskReqObj = {
            "id": task.id,
            "task_name": task.taskname,
            "task_desc": task.taskdescription,
            "planned_duration": Number(task.plannedduration),
            "planned_duration_unit": task.planneddurationunitsmanual.value,
          };
        }

        let taskResponse = await updateTask(taskReqObj);
        updatedTaskIds.push(task.id);
      }
      else if (validTask(task) && !taskIds.includes(task.id)) {
        let plannedStartDate: any = validDate(task.plannedstartdate);
        let plannedEndDate: any = getPlannedEndDate(plannedStartDate, task.plannedduration, task.planneddurationunitsmanual.value);

        let taskReqObj: any;
        if (values.planradiobuttons === "m") {
          taskReqObj = {
            "plan_id": props.formData?.id,
            "task_name": task.taskname,
            "task_desc": task.taskdescription,
            "planned_duration": Number(task.plannedduration),
            "planned_duration_unit": task.planneddurationunitsmanual.value,
            "planned_start_date": plannedStartDate,
            "planned_end_date": plannedEndDate
          };
        } else if (values.planradiobuttons === "a") {
          taskReqObj = {
            "plan_id": props.formData?.id,
            "task_name": task.taskname,
            "task_desc": task.taskdescription,
            "planned_duration": Number(task.plannedduration),
            "planned_duration_unit": task.planneddurationunitsmanual.value,
          };
        }
        let taskResponse = await createTask(taskReqObj);
        updatedTaskIds.push(taskResponse.id);
      }
    }

  }

  async function onSave() {
    if (validateValues(["name", "description"])) {
      showToaster(toasterTypes.ERROR, "Please enter the proper values in the fields highlighted in red");
    }
    else if (validatePlanDates()) {
      showToaster(toasterTypes.ERROR, "Please enter the Plan Start Date and End Date");
    }
    else if (validateTaskNameAndDesc()) {
      showToaster(toasterTypes.ERROR, "Please enter the Task Name and Description");
    }
    else if (values.planradiobuttons === "a" && validateTasksPlannedDurationDifferentUnits()) {
      setPlannedDurationError(true);
      showToaster(toasterTypes.ERROR, "Please ensure that Tasks planned duration does not exceed the Total Plan's duration.");
    }
    else if (values.planradiobuttons === "m" && validateTasksPlannedDurationDifferentUnits()) {
      setPlannedDurationError(true);
      showToaster(toasterTypes.ERROR, "Please ensure that Tasks planned duration does not exceed the Total Plan's duration.");
    }
    else if (validateTasksPlannedDurationGreaterThanZero()) {
      showToaster(toasterTypes.ERROR, "Tasks Planned Duration cannot be 0 or empty");
    }
    else {
      try {
        dispatch(setLoading(true));
        let response: any;
        if (props.isEdit) {
          let planStartDate: any = validDate(values.startdate);
          let planEndDate: any = validDate(values.enddate);
          await (async () => {
            await updateTasks(tasks);

            let updatePlanObj: any = {
              "id": props.formData?.id,
              "plan_name": values.name,
              "plan_desc": values.description,
              "plan_start_date": planStartDate,
              "plan_end_date": planEndDate,
              "auto_tasks_schedule": values.planradiobuttons === "a",
              "task_ids": updatedTaskIds,
            }
            let updatedResponse: any = await updatePlan(updatePlanObj);
            response = updatedResponse;
          })();
        } else {

          let reqObj: any = {
            "topic_id": props.projectData?.TopicID || props.projectData?.TopicCode,
            "project_id": props.projectData?.id,
            "plan_name": values.name,
            "plan_desc": values.description,
            "plan_start_date": values.startdate.toISOString(),
            "plan_end_date": values.enddate.toISOString(),
            "auto_tasks_schedule": values.planradiobuttons === "a",
            "task_ids": taskIds,
          }
          response = await createPlan(reqObj);

          let taskResponse: any;
          (async () => {
            for (const task of tasks) {
              if (validTask(task)) {
                let plannedStartDate: any = validDate(task.plannedstartdate);
                let plannedEndDate: any = getPlannedEndDate(plannedStartDate, Number(task.plannedduration), task.planneddurationunitsmanual.value);
                let taskReqObj: any;
                if (values.planradiobuttons === "m") {
                  taskReqObj = {
                    "plan_id": response.id,
                    "task_name": task.taskname,
                    "task_desc": task.taskdescription,
                    "planned_duration": Number(task.plannedduration),
                    "planned_duration_unit": task.planneddurationunitsmanual.value,
                    "planned_start_date": plannedStartDate,
                    "planned_end_date": plannedEndDate
                  };
                } else if (values.planradiobuttons === "a") {
                  taskReqObj = {
                    "plan_id": response.id,
                    "task_name": task.taskname,
                    "task_desc": task.taskdescription,
                    "planned_duration": Number(task.plannedduration),
                    "planned_duration_unit": task.planneddurationunitsmanual.value,
                  };
                }
                let taskResponse = await createTask(taskReqObj);
                taskIds.push(taskResponse.id);
              }
            }

            if (taskIds.length > 0) {
              let updatePlanObj: any = {
                "id": response.id,
                "plan_name": values.name,
                "plan_desc": values.description,
                "plan_start_date": values.startdate.toISOString(),
                "plan_end_date": values.enddate.toISOString(),
                "auto_tasks_schedule": values.planradiobuttons === "a",
                "task_ids": taskIds,
              }
              let updatedResponse: any = await updatePlan(updatePlanObj);
              response = updatedResponse;
            }

          })();



        }
        dispatch(setLoading(false));
        showToaster(toasterTypes.SUCCESS, response.message);
        props.onFormCreation();
      } catch (e: any) {
        dispatch(setLoading(false));
      }
    }
  }


  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const reorderedTasks = Array.from(tasks);
    const [reorderedItem] = reorderedTasks.splice(result.source.index, 1);
    reorderedTasks.splice(result.destination.index, 0, reorderedItem);


    setTasks(reorderedTasks);
  };

  function changePlanTemplates(value) {
    if (value && value.value) {
      setPlanTemplateSelectedValue(value)
      fetchPlanTemplateTasks(value.value)
    }

  }

  async function fetchPlanTemplateTasks(id) {
    try {
      dispatch(setLoading(true));
      let response = await getPlanTemplateTasks(id);
      const newTasks = response.data.map((d, index) => ({
        id: index + 1,
        taskname: d.task_name,
        taskdescription: d.task_desc,
        plannedduration: d.planned_duration,
        planneddurationunits: { value: d.planned_duration_unit, label: d.planned_duration_unit },
        planneddurationunitsmanual: { value: d.planned_duration_unit, label: d.planned_duration_unit },
        plannedstartdate: null,
      }));
      setTasks(newTasks);
      setPlannedDurationUnitState(newTasks[0].planneddurationunits);
      dispatch(setLoading(false));

    } catch (e: any) {
      dispatch(setLoading(false));
      showToaster(toasterTypes.ERROR, "Failed To fetch Plan Templates Tasks");
    }
  }


  return (
    <>
      {showPopup && (
        <ModalComponent
          width={"350px"}
          isOpen={true}
          onClose={() => setShowPopup(false)}
          header={"Download Option"}
          centered={true}
          customClass="gantt-download-modal"
          body={
            <>
              <Col>
                <Row>The Total Duration of the Tasks exceeds Plan End Date. Do You Want to Override the Plan End Date?</Row>
                <Row >
                  <Col>
                    <CustomButton
                      type="primary"
                      text="Yes"
                      onClick={() => handlePopupResponse(true)}
                    ></CustomButton>
                  </Col>
                  <Col>
                    <CustomButton
                      type="primary"
                      text="No"
                      onClick={() => handlePopupResponse(false)}
                    ></CustomButton>
                  </Col>
                </Row>
              </Col>
            </>
          }
          footer={<></>}
        />
      )}
      <ModalComponent
        width={window.innerWidth > 700 ? "1300px" : window.innerWidth}
        isOpen={true}
        onClose={() => props.onClose()}
        header={props.isView ? ("View Planner") : (props.isEdit ? "Edit Planner" : "Create Planner")}
        centered={true}
        customClass="profile-modal planner-form-modal"
        body={
          <div className="create-tasks-wrapper">

            <Row>
              <Col md={8}>
                <FieldItem
                  {...CreateFormElements.name}
                  value={values.name}
                  onChange={(...e) =>
                    handleChange(CreateFormElements.name.name, ...e)
                  }
                  touched={fields.name && fields.name.hasError}
                  error={fields.name && fields.name.errorMsg}
                  isDisabled={props.isView}
                />
              </Col>
            </Row>
            <Row>
              <Col md={8}>
                <FieldItem
                  {...CreateFormElements.description}
                  value={values.description}
                  onChange={(...e) =>
                    handleChange(CreateFormElements.description.name, ...e)
                  }
                  touched={fields.description && fields.description.hasError}
                  error={fields.description && fields.description.errorMsg}
                  isDisabled={props.isView}
                />
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <FieldItem
                  {...CreateFormElements.startdate}
                  value={values.startdate}
                  onChange={(...e) =>
                    handleChange(CreateFormElements.startdate.name, ...e)
                  }
                  touched={fields.startdate && fields.startdate.hasError}
                  error={fields.startdate && fields.startdate.errorMsg}
                  isDisabled={props.isView}
                />
              </Col>
              <Col md={3}>
                <FieldItem
                  {...CreateFormElements.enddate}
                  value={values.enddate}
                  onChange={(...e) =>
                    handleChange(CreateFormElements.enddate.name, ...e)
                  }
                  touched={fields.enddate && fields.enddate.hasError}
                  error={fields.enddate && fields.enddate.errorMsg}
                  isDisabled={props.isView}
                />
              </Col>
              <Col md={3}>
                <FieldItem
                  {...CreateFormElements.planRadioButtons}
                  value={values.planradiobuttons}
                  onChange={(...e) => {
                    handleChange(CreateFormElements.planRadioButtons.name, ...e)
                  }}
                  isDisabled={props.isView}
                />
              </Col>
              {props.isTemplate && <Col md={3}>
                <FieldItem
                  {...CreateFormElements.planTemplates}
                  value={planTemplateSelectedValue}
                  values={planTemplates}
                  onChange={(...e) => {
                    changePlanTemplates(...e)
                  }}
                  isDisabled={props.isView || props.isEdit}
                />
              </Col>}
            </Row>
            <div>
              <div>
                <div className="create-tasks-border">
                  <Row className="m-0">
                    <Col className="p-0">
                      <div className="create-tasks-text">Tasks</div>
                    </Col>
                  </Row>
                </div>
              </div>
              <div className="tasks-list">
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="tasks">
                    {(provided) => (
                      <div {...provided.droppableProps} ref={provided.innerRef}>
                        {tasks.map((obj: any, index) => (
                          <Draggable key={obj.id} draggableId={obj.id.toString()} index={index} isDragDisabled={true}>
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <Row className="create-tasks-row" key={index}>
                                  <Col md="6">
                                    <FieldItem
                                      {...CreateFormElements.taskName}
                                      value={obj.taskname}
                                      onChange={(...e) =>
                                        changeTasks(CreateFormElements.taskName.name, ...e, index)
                                      }
                                      isDisabled={props.isView}
                                    />
                                  </Col>
                                  <Col md="6">
                                    <FieldItem
                                      {...CreateFormElements.taskDescription}
                                      value={obj.taskdescription}
                                      onChange={(...e) =>
                                        changeTasks(
                                          CreateFormElements.taskDescription.name,
                                          ...e,
                                          index
                                        )
                                      }
                                      isDisabled={props.isView}
                                    />
                                  </Col>
                                  {values.planradiobuttons === "m" ? (<Col md="3" className="plannedstartdate" >
                                    <FieldItem
                                      {...CreateFormElements.plannedStartDate}
                                      value={obj.plannedstartdate}
                                      onChange={(...e) =>
                                        changeTasks(
                                          CreateFormElements.plannedStartDate.name,
                                          ...e,
                                          index
                                        )
                                      }
                                      isDisabled={props.isView}
                                    />
                                  </Col>) : ("")}
                                  {values.planradiobuttons === "m" ? (<Col md="3" className="plannedstartdate" disabled>
                                    <FieldItem
                                      {...CreateFormElements.plannedEndDate}
                                      value={obj.plannedenddate}
                                      onChange={(...e) =>
                                        changeTasks(
                                          CreateFormElements.plannedEndDate.name,
                                          ...e,
                                          index
                                        )
                                      }
                                      isDisabled={true}
                                    />
                                  </Col>) : ("")}
                                  <Col md="2">
                                    <FieldItem
                                      {...CreateFormElements.plannedDuration}
                                      value={obj.plannedduration}
                                      onChange={(...e) =>
                                        changeTasks(
                                          CreateFormElements.plannedDuration.name,
                                          ...e,
                                          index
                                        )
                                      }
                                      error={plannedDurationError}
                                      isDisabled={props.isView}
                                    />
                                  </Col>
                                  <Col md="2">
                                    <FieldItem
                                      {...CreateFormElements.plannedDurationUnits}
                                      value={obj.planneddurationunitsmanual}
                                      values={CreateFormElements.plannedDurationUnits.values.filter(item => item.value !== "Percentage")}
                                      onChange={(...e) => {
                                        changeTasks(
                                          "planneddurationunitsmanual",
                                          ...e,
                                          index
                                        )
                                      }
                                      }
                                      onBlur={()=>setIsDragDisabledState(false)}
                                      onFocus={()=>setIsDragDisabledState(true)}
                                      isDisabled={props.isView}
                                    />
                                    {/* <select>
                                      <option value="Days">Days</option>
                                      <option value="Months">Months</option>
                                      <option value="Years">Years</option>
                                    </select> */}
                                  </Col>
                                    {/* <Col md="2">
                                      <FieldItem
                                        {...CreateFormElements.plannedDurationUnits}
                                        value={plannedDurationUnitState}
                                        values={CreateFormElements.plannedDurationUnits.values}
                                        onChange={(...e) => {
                                          resetPlannedDurationValues(
                                            ...e
                                          )
                                        }
                                        }
                                        isDisabled={props.isView}
                                      />
                                    </Col> */}
                                  
                                  <Col md={1} style={{
                                    position: 'absolute',
                                    bottom: '3rem',
                                    right: 0
                                  }}>
                                    {!props.isView && tasks.length >= 1 ? (
                                      <DeleteFilled
                                        style={{
                                          color: "red", marginTop: '12px'
                                        }}
                                        onClick={() => removeTask(obj.id)}
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </Col>
                                </Row>
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>

              </div>
              {
                tasks && tasks.length == 0 && (
                  <div>No Tasks Found</div>
                )
              }
              <div className="create-tasks-header">
                <div className="create-tasks-border">
                  <Row className="m-0">
                    <Col className="p-0">
                      <div className="create-tasks-text">{props.isView ? "Tasks" : "Create Tasks"}</div>
                    </Col>
                    <Col className="p-0">
                      {!props.isView && (
                        <div className="add-task-text">
                          <p onClick={() => addTask()}>Add Task</p>
                        </div>
                      )}
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        }
        footer={
          <div>
            <CustomButton
              type="primary"
              isDisabled={props.isView}
              text="Save"
              onClick={() => onSave()}
            ></CustomButton>
          </div>
        }
      />
    </>
  );
}
