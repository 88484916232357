import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { setLoading } from "../../store/slices/auth";
import { CustomButton, showToaster, toasterTypes } from "../../widgets";
import { FIELD_TYPES, FieldItem } from "../../widgets/fields";
import useFieldItem from "../../widgets/fields/UseFieldItem";
import { getFormRecords, getForms, getGroups } from "../dataCollection/apiController";
import { GroupFormElements } from "./FieldElements";
import { CloseOutlined } from "@ant-design/icons";
import { getFormData, getVariable, postGroupVariable } from "./apiController";
import FormulaEditor from "./FormulaEditor";
import { group } from "console";

export default function GroupVariable(props) {
    const [values, fields, handleChange, { validateValues, reset, updateValue, updateError }] = useFieldItem(GroupFormElements, {}, { onValueChange });
    const [variableList, SetVariableList] = useState<any>([]);
    const [selectedgroupType, SetGroupType] = useState("");
    const [projectData, SetProjectData] = useState<any>([]);
    const [groupData, SetGroup] = useState<any>([]);
    const [variableData, SetvariableData] = useState<any>([]);
    const [itemCount, SetItemCount] = useState(0);
    const [count, SetCount] = useState(0);
    const [formList, SetFormList] = useState<any>([]);
    const [formvariables, setformVariables] = useState<any>([]);
    const [formAddvariables, setformAddVariables] = useState<any>([]);
    const [formvariablesValue, setformVariablesValue] = useState<any>([]);
    const dispatch = useDispatch();
    let componentObj = {};
    const [isSuggestion, SetSuggestion] = useState(false);
    const [numericValues, SetNurmericValues] = useState<any>([]);
    const [pageSize, SetPageSize] = useState(10);
    const [variables, Setvariables] = useState<any>([])
    const [popupType, SetpopupType] = useState("");
    const [formuladata, Setformuladata] = useState<any>({ display_formula: "", formula: "" });
    const [defaultInput, setDefaultInput] = useState(false);
    let ComputeOperators = [
        { value: ">", label: ">" },
        { value: "<", label: "<" },
        { value: "==", label: "==" },
        { value: ">=", label: ">=" },
        { value: "<=", label: "<=" },
        { value: "!=", label: "!=" },
        { value: "contains", label: "contains" },
        { value: "and", label: "and" },
        { value: "or", label: "or" },
        { value: "not", label: "not" },
        { value: "in", label: "in" },
        { value: "+", label: "+" },
        { value: "-", label: "-" },
        { value: "*", label: "*" },
        { value: "/", label: "/" },
        { value: "%", label: "%" },
        { value: "(", label: "(" },
        { value: ")", label: ")" },
        { value: "[", label: "[" },
        { value: "]", label: "]" },
        { value: "sqrt", label: "sqrt" },
        { value: "square", label: "square" },
        { value: "cube", label: "cube" },
        { value: "power", label: "power" }
    ]
    // const { variables } = props;
    let Operators = [{ value: ">", label: ">" },
    { value: "<", label: "<" },
    { value: "==", label: "==" },
    { value: ">=", label: ">=" },
    { value: "<=", label: "<=" },
    { value: "!=", label: "!=" },
    { value: "contains", label: "contains" }];

    let LogicalOpt = [{ value: "Select", label: "Select" },
    { value: "and", label: "and" },
    { value: "or", label: "or" }];

    /**
    * on value change update form data
    * @param name 
    * @param value 
    * @param values 
    * @param fieldValues 
    * @returns 
    */
    function onValueChange(name, value, values, fieldValues = null) {
        let resetValue = {};
        console.log(values)
        if (name == "grouptype") {
            fetchFormVariables(value.label);
            SetGroupType(value.label)
            SetVariableList([])
            SetpopupType("")
            values.variable = {}
            values.newcolumnname = [];
            values = [];
        } else if (name == "variable") {
            SetItemCount(0);
            SetVariableList([]);
            SetItemCount(itemCount + 1);
            values.newcolumnname = [];
            console.log(value)
            if (value.type == "radioButton" || value.type == "checkBox" || value.type == "dropDown") {
                SetGroupType("Categorical")
                getVariableData(value.value, "")
            } else {
                SetGroupType("Numeric")
                SetvariableData([])
            }
            let componentObj = formvariablesValue.filter(item => item.field_name == value.label);
            SetVariableList(componentObj)
            console.log(value)
        } else if (value?.type == "radioButton" || value?.type == "dropDown" || value?.type == "checkBox") {
            getVariableData(value.value, name[name.length - 1])
            console.log(variableList)
        } else if (name.replace(/[^A-Za-z]/g, "") == "logic" && value.label != "Select") {
            addValuesComponent("")
        }

        return [{
            ...values,
            ...resetValue,
            [name]: value,
        }]
    }


    useEffect(() => {
        console.log(props)
        fetchColumns();
        SetProjectData(props.data)
        fetchGroups(props.data.TopicID)
        fetchFormVariables("");
    }, [])

    useEffect(() => {
        console.log(variableList)
    }, [count,variableList, selectedgroupType, itemCount, formvariables, variables])

    /**
     * get variable data for selected variable 
     * @param id 
     */
    const getVariableData = async (id, key) => {
        try {
            let response = await getVariable(id);
            console.log(key)
            if (key) {
                let fieldValues: any = [];
                response.field_value.map((item, i) => {
                    let Obj = {
                        label: item,
                        value: item
                    }
                    fieldValues.push(Obj)
                })
                variableList.filter(item => item.key == key).map((items) => {
                    items.variablelist = fieldValues
                })
                console.log(variableList)
                SetCount(count+1)
            } else {
                SetvariableData(response.field_value)
            }
        } catch (e: any) {

        }
    }
    /**
     * get variables data
     * @param formData 
     */
    const fetchFormVariables = async (groupdata) => {
        try {
            setformVariables([]);
            setformVariablesValue(variables);
            if (groupdata == "Categorical") {
                variables.filter(itemfield => itemfield.field_type != "number").map((item, i) => {
                    let Obj = {
                        label: item.label,
                        value: item.id,
                        type: item.field_type
                    }
                    setformVariables(prevState => [...prevState, Obj]);
                })
            } else if (groupdata == "Numerical") {
                variables.filter(itemfield => itemfield.field_type == "number").map((item, i) => {
                    let Obj = {
                        label: item.label,
                        value: item.id,
                        type: item.field_type
                    }
                    setformVariables(prevState => [...prevState, Obj]);
                })
            } else if (groupdata == "Compute") {
                variables.map((item, i) => {
                    let Obj = {
                        label: item.label,
                        value: item.id,
                        type: item.field_type
                    }
                    let ObjAdd = {
                        label: item.label,
                        value: item.label,
                        id: item.id
                    }
                    setformVariables(prevState => [...prevState, Obj]);
                    setformAddVariables(prevState => [...prevState, ObjAdd]);
                })
            }

        } catch (e: any) {
            setformVariables([]);
        }
    }

    /**
     * get variable list
     */
    const fetchColumns = async () => {
        try {
            dispatch(setLoading(true));
            let response = await getFormRecords(props?.formData.id, 1, pageSize);
            if (response.data && response.data.length > 0) {
                let tempHeaders: any = [];
                response.data.forEach((obj, index) => {
                    obj.fields.forEach(subObj => {
                        if (index > 0) {
                            let check = tempHeaders.filter(elem => elem.value === subObj.id);
                            if (check.length === 0) {
                                tempHeaders.push({
                                    label: subObj.field_name,
                                    value: subObj.id,
                                    data: subObj,
                                    type: subObj.field_type
                                });
                            }
                        } else {
                            tempHeaders.push({
                                label: subObj.field_name,
                                value: subObj.id,
                                data: subObj,
                                type: subObj.field_type
                            });
                        }
                    });
                });
                console.log(tempHeaders)

                let tempColumns: any = [];
                tempHeaders.forEach(obj => {
                    tempColumns.push({
                        "id": obj.value,
                        "label": obj.label,
                        accessor: elem => (obj.data.field_type === 'fileUpload' && elem[obj.value]) ? <div className='link-text' onClick={() => window.open(elem[obj.value])}>Link</div> : elem[obj.value]?.toString() || '-',
                        "sort": "asc",
                        "field_type": obj.type
                    })
                })

                Setvariables(tempColumns);
            }
            dispatch(setLoading(false));
        } catch (e: any) {
            dispatch(setLoading(false));
        }
    };


    /**
     * remove add value component
     */
    const removeComponent = (id) => {
        console.log(id, variableList)
        let updatedVariables = variableList.filter(item => item.key != id);
        delete values["values" + id]
        delete values["newcode" + id]
        delete values["logic" + id]
        delete values["values" + id]
        delete values["opt" + id]
        delete values["val" + id]
        //updateValue({["values"+id]:"",["newcode"+id]:""});
        SetVariableList(updatedVariables);
        SetpopupType("");
        console.log(values);
        SetItemCount(itemCount - 1);
    }

    /**
    * add value component to state
    */
    const addValuesComponent = async (type) => {
        SetpopupType(type)
        if (values.variable?.value) {
            SetItemCount(itemCount + 1)
            await formvariablesValue.filter(item => item.id == values['variable'].value).map(itemvalue => {
                componentObj['key'] = variableList[variableList?.length - 1] ? variableList[variableList.length - 1].key + 1 : 1;
                componentObj['newcode'] = "";
                componentObj['variablelist'] = [];
                componentObj['field_use_type'] = itemvalue.field_type;
                let fieldValues: any = [];
                variableData.map((item, i) => {
                    let Obj = {
                        label: item,
                        value: item
                    }
                    fieldValues.push(Obj)
                })
                componentObj['field_value'] = fieldValues;
            });
            SetSuggestion(variableList.length == 0 ? true : false)
            console.log(componentObj)
            SetVariableList(prevState => [...prevState, componentObj]);
        } else {
            updateError("variable", { hasError: true, errorMsg: "Please select variable" });
        }

        if (values.grouptype?.label == "Compute") {
            SetItemCount(itemCount + 1)
            await formvariablesValue.map(itemvalue => {
                componentObj['key'] = variableList[variableList?.length - 1] ? variableList[variableList.length - 1].key + 1 : 1;
                componentObj['newcode'] = "";
                componentObj['variablelist'] = [];
                componentObj['field_use_type'] = "";
                let fieldValues: any = [];
                componentObj['field_value'] = fieldValues;
            });
            SetSuggestion(variableList.length == 0 ? true : false)
            console.log(componentObj)
            SetVariableList(prevState => [...prevState, componentObj]);
        }
        console.log(variableList)
    }

  
    /**
     * get groups
     * @param id 
     */
    const fetchGroups = async (id) => {
        try {
            dispatch(setLoading(true));
            let tempGroups = await getGroups(id);
            SetGroup(tempGroups?.data || []);
            fetchForms(tempGroups?.data[0].id);
            dispatch(setLoading(false));
        } catch (e: any) {
            SetGroup([]);
            dispatch(setLoading(false));
        }
    };

    /**
     * fetch form list
     * @param index 
     * @param id 
     */
    const fetchForms = async (id) => {
        try {
            dispatch(setLoading(true));
            let tempForms = await getForms(id);
            SetFormList([]);
            tempForms.map((item, i) => {
                let Obj = {
                    label: item.form_name,
                    value: item.id
                }
                SetFormList(prevState => [...prevState, Obj]);
            })
            SetSuggestion(true)
            dispatch(setLoading(false));
        } catch (e: any) {
            dispatch(setLoading(false));
        }
    };

    /**
     * get values from the add variable component
     */
    const getValues = (name, value, code) => {
        console.log(name, value, code)
        let isExist = numericValues.filter(item => item.key == name)
        console.log(isExist.length)
        if (isExist.length != 0) {
            let removeItem = numericValues.filter(item => item.key != name)
            SetNurmericValues(removeItem)
            SetNurmericValues(prevState => [...prevState, { "key": name, "oldValues": [code], "newValue": value }])
        } else {
            SetNurmericValues(prevState => [...prevState, { "key": name, "oldValues": [code], "newValue": value }])
        }
        console.log(numericValues)
    }

    /**
     * remove item from the array of numeric and compute objects
     */
    const removeItem = (key) => {
        console.log(key, variableList)
        let removeItem = numericValues.filter(item => item.key != "newcode" + key)
        SetNurmericValues(removeItem)
        console.log(removeItem)
        let updatedVariables = variableList.filter(item => item.key != key);
        //updateValue({["values"+id]:"",["newcode"+id]:""});
        console.log(updatedVariables)
        SetVariableList(updatedVariables);
        SetItemCount(itemCount - 1);
    }

    /**
     * recreate the array of items for submissions
     * call to API
     */
    const saveData = async () => {
        let regroupfields: any = [];
        if (values.grouptype.value == "category_grouping") {
            variableList.map((item, index) => {
                let oldCodesVal: any = [];
                let condition: any = [];
                console.log(values)
                typeof values["values" + item.key] == "object" && values["values" + item.key].map(items => {
                    console.log(items)
                    oldCodesVal.push(items.label)
                })
                //newCode: values["newcode" + item.key],
                condition.push({
                    "key": "field",
                    "field_id": values.variable.value,
                    "value_type": typeof values["values" + item.key] == "object" ? "list" : "contains",
                    "value": typeof values["values" + item.key] == "object" ? oldCodesVal : values["val" + item.key],
                    "display_value": values.variable.label+" Contains "+ (typeof values["values" + item.key] == "object" ? oldCodesVal : values["val" + item.key])
                })

                regroupfields.push({
                    expression_type: "condition",
                    "conditions": condition,
                    value: values["newcode" + item.key]
                })
            })
        } else if (values.grouptype.value == "numericgrouping") {
            let condition: any = [];
            let valueField = "";
            console.log(values)
            variableList.map((item, index) => {
                condition.push({
                    "key": "field",
                    "field_id": values.variable.value,
                    "value_type": "expression",
                    "display_value":values.variable.label+ values["opt" + item.key]?.value + values["val" + item.key],
                    "value": "x" + values["opt" + item.key]?.value + values["val" + item.key]
                })

                if (!values["newcode" + item.key] && values["logic" + item.key]?.value != "Select" && values["logic" + item.key]) {
                    condition.push({
                        "key": "condition",
                        "value_type": "logical_operator",
                        "value": values["logic" + item.key]?.value
                    })
                }
                valueField = values["newcode" + item.key]

                if (values["newcode" + item.key]) {
                    regroupfields.push({
                        expression_type: "condition",
                        "conditions": condition,
                        value: valueField
                    })

                    condition = []
                }
            })
            // regroupfields.push({
            //     "conditions": condition,
            //     value: valueField
            // })
        } else {
            let condition: any = [];
            let valueField = "";
            variableList.map((item, index) => {
                if (values["opt" + item.key]?.value == "contains" || Array.isArray(values["val" + item.key]) || typeof (values["val" + item.key]) == "object") {
                    let oldCodesVal: any = [];
                    console.log(typeof (values["val" + item.key]))
                    if (Array.isArray(values["val" + item.key])) {
                        values["val" + item.key].map(items => {
                            console.log(items)
                            oldCodesVal.push(items.label)
                        })
                    }else if(typeof (values["val" + item.key]) == "object"){
                        oldCodesVal.push(values["val" + item.key].label)
                    }

                    console.log(oldCodesVal)
                    condition.push({
                        "key": "field",
                        "field_id": values["values" + item.key]?.value,
                        "value_type": "list",
                        "display_value":Array.isArray(values["val" + item.key]) ? values["values" + item.key]?.label+" contains " + oldCodesVal : typeof (values["val" + item.key]) == "object" ? values["values" + item.key]?.label+" contains " + oldCodesVal :  values["values" + item.key]?.label +" Contains " +values["val" + item.key],
                        "value": Array.isArray(values["val" + item.key]) ? oldCodesVal : typeof (values["val" + item.key]) == "object" ? oldCodesVal : [values["val" + item.key]]
                    })
                } else {
                    console.log(values["values" + item.key]?.type)
                    let valuesData = "";
                    let displayData = "";

                    if (values["values" + item.key]?.type == "radioButton" || values["values" + item.key]?.type == "checkBox") {
                        valuesData = `'x'` + values["opt" + item.key]?.value +`'`+values["val" + item.key].label+`'`
                        displayData = values["values" + item.key]?.label + values["opt" + item.key]?.value +`'`+values["val" + item.key].label+`'`

                    } else {
                        valuesData = values["val" + item.key];
                        if(values["values" + item.key].type != "number"){
                            valuesData = `'x'` + values["opt" + item.key]?.value +`'`+valuesData+`'`
                            displayData = values["values" + item.key]?.label + values["opt" + item.key]?.value +values["val" + item.key]
                        }else{
                            valuesData = 'x' + values["opt" + item.key]?.value + values["val" + item.key]
                            displayData = values["values" + item.key]?.label +`'`+values["val" + item.key]+`'`
                        }
                    }
                    condition.push({
                        "key": "field",
                        "field_id": values["values" + item.key]?.value,
                        "value_type": "expression",
                        "value":  valuesData,
                        "display_value":displayData
                    })
                }

                if (!values["newcode" + item.key] && values["logic" + item.key]?.value != "Select" && values["logic" + item.key]) {
                    condition.push({
                        "key": "condition",
                        "value_type": "logical_operator",
                        "value": values["logic" + item.key].value
                    })
                }

                valueField = values["newcode" + item.key]

                if (values["newcode" + item.key]) {
                    regroupfields.push({
                        expression_type: "condition",
                        "conditions": condition,
                        value: valueField
                    })
                    condition = []
                }
            })
            // regroupfields.push({
            //     "conditions": condition,
            //     value: valueField
            // })
        }
       
        console.log(regroupfields)
        let topicId = props.data.TopicID;

        let finalObj = {
            "topic_id": topicId,
            "field_name": values.newcolumnname || "",
            "field_description": values.newcolumnname,
            "field_type": values.variablefieldtype.value,
            "field_use_type": "Grouping",
            "expression": regroupfields,
            "metadata":{
                group_type: values.grouptype,
                variable: values.variable,
            },
            "default_value": values?.origionalvalue.value == "custom" ? values.defaultvalue : "$$original_value$$"
        }

        console.log(finalObj)

        try {
             dispatch(setLoading(true));
            let submitResponse = await postGroupVariable(props && props.formData?.id, finalObj);
            if (submitResponse) {
                showToaster(toasterTypes.SUCCESS, "Variable created Successfully")
                dispatch(setLoading(false));
                props.onClose();
            }
        } catch (e: any) {
            console.log(e)
            dispatch(setLoading(false));
        }
    }

    /**
     * save formula
     */
    const saveFormula = async (formulaExpression, actualExpression,rawData) => {
        let topicId = props.data.TopicID;
        let formulaName = values["newcolumnname"];
        formulaExpression = "";
        //create formula from actual expression
        console.log(actualExpression,"-----",formulaExpression,"RWa;;;;;",rawData)
        rawData.split("$$").map(itemvar =>{
            console.log(itemvar.trim())
            let formVariable = formAddvariables.filter(item => item.label == itemvar.trim())
            console.log(formVariable)
            if(formVariable.length > 0){
                formulaExpression += "$$"+formVariable[0].id+"$$"
            }else{
                formulaExpression += itemvar+" "
            }
        })
        console.log(formulaExpression)


        let formulaGroup = {
            expression_type: "formula",
            value: formulaName,
            display_formula: actualExpression,
            formula: formulaExpression
        }
      
        let update = formulaExpression.trim().split(" ").join("$$")
        console.log(formulaExpression, "" ,actualExpression)

        let finalObj = {
            "topic_id": topicId,
            "field_name": values.newcolumnname || "",
            "field_description": values.newcolumnname,
            "field_type": values.variablefieldtype.value,
            "field_use_type": "Grouping",
            "metadata":{
                group_type: values.grouptype,
                variable: values.variable,
            },
            "expression": [formulaGroup]
        }

        console.log(finalObj)
        try {
            if (formulaExpression && actualExpression) {
                dispatch(setLoading(true));
                let submitResponse = await postGroupVariable(props && props.formData?.id, finalObj);
                if (submitResponse) {
                    showToaster(toasterTypes.SUCCESS, "Variable created Successfully")
                    dispatch(setLoading(false));
                    props.onClose();
                }
            }
        } catch (e: any) {
            console.log(e)
            dispatch(setLoading(false));
        }
    }
    /**
     * get formula data from ADd Formula component call back function
     */
    const getFormula = async (formulaExpression, actualExpression, rawData: any) => {
        console.log(formulaExpression)
        console.log(rawData)
        Setformuladata({ display_formula: actualExpression, formula: formulaExpression })
       await saveFormula(formulaExpression, actualExpression, rawData);
    }

    /**
     * onclose popup
     */
    const onClose = () => {
        SetpopupType("")
        Setformuladata({})
    }

    return (
        <>
            <div className="grouping-container">
                <div className="header-container">
                    <Row className='m-0'>
                        <Col className='pl-4 mt-3' md={10}>
                            <div className='form-name'>{projectData?.TopicDesc}</div>
                        </Col>
                        <Col className='pr-4 mt-4 text-right' md={2}>
                            <i className='icon icon-close' onClick={() => props.onClose()} />
                        </Col>
                    </Row>
                </div>
                <Row className='mt-2 mr-2 ml-2'>

                    <Col md={3}>
                        <FieldItem
                            {...GroupFormElements.grouptype}
                            value={values.grouptype}
                            values={GroupFormElements.grouptype.values}
                            onChange={(...e) => handleChange(GroupFormElements.grouptype.name, ...e)}
                            touched={fields.grouptype && fields.grouptype.hasError}
                            error={fields.grouptype && fields.grouptype.errorMsg}
                        />
                    </Col>

                    {values && values.grouptype?.value != "Advanced_compute" ?
                        <>
                            <Col md={3}>
                                <FieldItem
                                    {...GroupFormElements.variable}
                                    value={values.variable}
                                    values={formvariables}
                                    isMandatory={selectedgroupType == "Compute" ? false : true}
                                    onChange={(...e) => handleChange(GroupFormElements.variable.name, ...e)}
                                    touched={fields.variable && fields.variable.hasError}
                                    error={fields.variable && fields.variable.errorMsg}
                                />
                            </Col>
                        </>
                        : ""}
                    <Col md={3}>
                        <FieldItem
                            {...GroupFormElements.newcolumnname}
                            value={values.newcolumnname}
                            onChange={(...e) => handleChange(GroupFormElements.newcolumnname.name, ...e)}
                            touched={fields.newcolumnname && fields.newcolumnname.hasError}
                            error={fields.newcolumnname && fields.newcolumnname.errorMsg}
                        />
                    </Col>
                    <Col md={3}>
                        <FieldItem
                            {...GroupFormElements.variablefieldtype}
                            value={values.variablefieldtype}
                            onChange={(...e) => handleChange(GroupFormElements.variablefieldtype.name, ...e)}
                            touched={fields.variablefieldtype && fields.variablefieldtype.hasError}
                            error={fields.variablefieldtype && fields.variablefieldtype.errorMsg}
                        />
                    </Col>
                </Row>
                <div className="m-3 add-variable">
                    {
                        variableList.length != 0 ?
                            popupType != "formula" ?
                                <Row className="m-3">
                                    <Col md={8}>
                                        <span>Values</span>
                                    </Col>
                                    <Col md={4}>
                                        <span>New Value / Code</span>
                                    </Col>
                                </Row>
                                :
                                ""
                            :
                            ""
                    }

                    {
                        popupType == "formula" && values && values.grouptype?.value == "Advanced_compute" && variableList?.length > 0 ?
                            variableList && variableList.map((item, index) => {
                                return <>
                                    <FormulaEditor values={item} key={index} removeComponent={removeComponent} variables={formAddvariables} getFormulaData={getFormula} />
                                </>
                            })
                            :
                            <div className="mt-2 mr-2 ml-2 values-list">
                                {console.log("---------------------",selectedgroupType)}
                                {
                                    variableList && variableList.map((item, index) => {
                                        return <>
                                            {
                                                selectedgroupType == "Categorical" ?
                                                    <div className="categorical-container" key={index}>
                                                        <Row>
                                                            <Col md={7}>
                                                                <FieldItem
                                                                    name={"values" + index}
                                                                    type={FIELD_TYPES.MULTI_SELECT}
                                                                    value={values["values" + item.key]}
                                                                    values={item.field_value}
                                                                    onChange={(...e) => handleChange("values" + item.key, ...e)}
                                                                />
                                                            </Col>
                                                            <Col md={4}>
                                                                <FieldItem
                                                                    name={"newcode" + index}
                                                                    value={values["newcode" + item.key]}
                                                                    values={[]}
                                                                    onChange={(...e) => handleChange("newcode" + item.key, ...e)}
                                                                    touched={fields["newcode" + item.key] && fields["newcode" + item.key].hasError}
                                                                    error={fields["newcode" + item.key] && fields["newcode" + item.key].errorMsg}
                                                                />
                                                            </Col>
                                                            <Col md={1}>
                                                                <CloseOutlined style={{ color: "red" }} onClick={() => removeComponent(item.key)} />
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                    : selectedgroupType == "Numeric" ?
                                                        <div className="categorical-container" key={index}>
                                                            <Row>
                                                                <Col md={2}>
                                                                    <FieldItem
                                                                        name={"values" + index}
                                                                        type={FIELD_TYPES.TEXT}
                                                                        value={values?.variable?.label}
                                                                        values={item.field_value}
                                                                        isDisabled={true}
                                                                        onChange={(...e) => handleChange("values" + item.key, ...e)}
                                                                    />
                                                                </Col>
                                                                <Col md={2}>
                                                                    <FieldItem
                                                                        name={"opt" + index}
                                                                        type={FIELD_TYPES.DROP_DOWN}
                                                                        value={values["opt" + item.key]}
                                                                        values={Operators}
                                                                        onChange={(...e) => handleChange("opt" + item.key, ...e)}
                                                                    />
                                                                </Col>
                                                                <Col md={2}>
                                                                    <FieldItem
                                                                        name={"val" + index}
                                                                        value={values["val" + item.key]}
                                                                        placeholder="Value"
                                                                        onChange={(...e) => handleChange("val" + item.key, ...e)}
                                                                        touched={fields["val" + item.key] && fields["val" + item.key].hasError}
                                                                        error={fields["val" + item.key] && fields["val" + item.key].errorMsg}
                                                                    />
                                                                </Col>
                                                                <Col md={2}>
                                                                    <FieldItem
                                                                        name={"logic" + index}
                                                                        type={FIELD_TYPES.DROP_DOWN}
                                                                        value={values["logic" + item.key]}
                                                                        values={LogicalOpt}
                                                                        onChange={(...e) => handleChange("logic" + item.key, ...e)}
                                                                    />
                                                                </Col>
                                                                {index + 1 == variableList.length ?
                                                                    <Col md={3}>
                                                                        <FieldItem
                                                                            name={"newcode" + index}
                                                                            value={values["newcode" + item.key]}
                                                                            values={[]}
                                                                            placeholder="New Code"
                                                                            onChange={(...e) => handleChange("newcode" + item.key, ...e)}
                                                                            touched={fields["newcode" + item.key] && fields["newcode" + item.key].hasError}
                                                                            error={fields["newcode" + item.key] && fields["newcode" + item.key].errorMsg}
                                                                        />
                                                                    </Col> :
                                                                    values["newcode" + item.key] ?
                                                                        <Col md={3}>
                                                                            <FieldItem
                                                                                name={"newcode" + index}
                                                                                value={values["newcode" + item.key]}
                                                                                values={[]}
                                                                                placeholder="New Code"
                                                                                onChange={(...e) => handleChange("newcode" + item.key, ...e)}
                                                                                touched={fields["newcode" + item.key] && fields["newcode" + item.key].hasError}
                                                                                error={fields["newcode" + item.key] && fields["newcode" + item.key].errorMsg}
                                                                            />
                                                                        </Col>
                                                                        : <Col md={1}></Col>}
                                                                <Col md={1}>
                                                                    <CloseOutlined style={{ color: "red" }} onClick={() => removeComponent(item.key)} />
                                                                </Col>
                                                            </Row>                                                            
                                                        </div>
                                                        :
                                                        <div className="compute-container" key={index}>
                                                            <Row>
                                                                <Col md={2}>
                                                                    <FieldItem
                                                                        name={"values" + index}
                                                                        type={FIELD_TYPES.DROP_DOWN}
                                                                        value={values["values" + item.key]}
                                                                        values={formvariables}
                                                                        onChange={(...e) => handleChange("values" + item.key, ...e)}
                                                                    />
                                                                </Col>
                                                                <Col md={2}>
                                                                    <FieldItem
                                                                        name={"opt" + index}
                                                                        type={FIELD_TYPES.DROP_DOWN}
                                                                        value={values["opt" + item.key]}
                                                                        values={ComputeOperators}
                                                                        onChange={(...e) => handleChange("opt" + item.key, ...e)}
                                                                    />
                                                                </Col>
                                                                <Col md={values["values" + item.key]?.type == "radioButton" ? 3 : 2}>
                                                                    <FieldItem
                                                                        name={"val" + index}
                                                                        value={values["val" + item.key]}
                                                                        placeholder="Value"
                                                                        type={values["values" + item.key]?.type == "radioButton" || values["values" + item.key]?.type == "checkbox" ? values["opt" + item.key]?.label == "contains" ? FIELD_TYPES.MULTI_SELECT : FIELD_TYPES.DROP_DOWN : values["values" + item.key]?.type == "dropDown" ? FIELD_TYPES.DROP_DOWN : FIELD_TYPES.TEXT}
                                                                        values={values["values" + item.key]?.type == "radioButton" || values["values" + item.key]?.type == "dropDown" ? item.variablelist : ""}
                                                                        onChange={(...e) => handleChange("val" + item.key, ...e)}
                                                                        touched={fields["val" + item.key] && fields["val" + item.key].hasError}
                                                                        error={fields["val" + item.key] && fields["val" + item.key].errorMsg}
                                                                    />
                                                                </Col>
                                                                <Col md={2}>
                                                                    <FieldItem
                                                                        name={"logic" + index}
                                                                        type={FIELD_TYPES.DROP_DOWN}
                                                                        value={values["logic" + item.key]}
                                                                        values={ComputeOperators}
                                                                        onChange={(...e) => handleChange("logic" + item.key, ...e)}
                                                                    />
                                                                </Col>
                                                                {index + 1 == variableList.length ?
                                                                    <Col md={values["values" + item.key]?.type == "radioButton" ? 2 : 3}>
                                                                        <FieldItem
                                                                            name={"values" + index}
                                                                            value={values["newcode" + item.key]}
                                                                            values={[]}
                                                                            placeholder="New Code"
                                                                            onChange={(...e) => handleChange("newcode" + item.key, ...e)}
                                                                            touched={fields["newcode" + item.key] && fields["newcode" + item.key].hasError}
                                                                            error={fields["newcode" + item.key] && fields["newcode" + item.key].errorMsg}
                                                                        />
                                                                    </Col>
                                                                    :
                                                                    values["newcode" + item.key] ?
                                                                        <Col md={values["values" + item.key]?.type == "radioButton" ? 2 : 3}>
                                                                            <FieldItem
                                                                                name={"values" + index}
                                                                                value={values["newcode" + item.key]}
                                                                                values={[]}
                                                                                placeholder="New Code"
                                                                                onChange={(...e) => handleChange("newcode" + item.key, ...e)}
                                                                                touched={fields["newcode" + item.key] && fields["newcode" + item.key].hasError}
                                                                                error={fields["newcode" + item.key] && fields["newcode" + item.key].errorMsg}
                                                                            />
                                                                        </Col>
                                                                        : <Col md={values["values" + item.key]?.type == "radioButton" ? 2 : 3}></Col>}
                                                                <Col md={1}>
                                                                    <CloseOutlined style={{ color: "red" }} onClick={() => removeComponent(item.key)} />
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                // <AddVariableComponent
                                                //     key={index}
                                                //     data={item}
                                                //     variablevalue={values?.variable?.label}
                                                //     grouptype={""}
                                                //     variable={formvariables}
                                                //     forms={formList}
                                                //     suggest={isSuggestion}
                                                //     remove={removeItem}
                                                //     getData={getValues} />
                                            }
                                        </>

                                    })
                                }
                                <div className="categorical-container" style={{height:"100px"}}>
                                    <Row>
                                    <Col md={4}>
                                            {variableList && variableList.length > 0 ?
                                                <FieldItem
                                                    {...GroupFormElements.origionalvalue}
                                                    value={values.origionalvalue}
                                                    onChange={(...e) => handleChange(GroupFormElements.origionalvalue.name, ...e)}
                                                    touched={fields.origionalvalue && fields.origionalvalue.hasError}
                                                    error={fields.origionalvalue && fields.origionalvalue.errorMsg}
                                                /> : ""}

                                        </Col>
                                        <Col md={4}>
                                            {values?.origionalvalue && values?.origionalvalue?.value == "custom" ?
                                                <FieldItem
                                                    {...GroupFormElements.defaultvalue}
                                                    value={values.defaultvalue}
                                                    onChange={(...e) => handleChange(GroupFormElements.defaultvalue.name, ...e)}
                                                    touched={fields.defaultvalue && fields.defaultvalue.hasError}
                                                    error={fields.defaultvalue && fields.defaultvalue.errorMsg}
                                                /> : ""}

                                        </Col>
                                        <Col md={2} className="mt-4">
                                            {values && values.grouptype?.value == "Advanced_compute" && variableList?.length == 0 ?
                                                <span className="add-value" onClick={() => addValuesComponent("formula")}>Add Formula</span>
                                                : ""
                                            }
                                        </Col>
                                        <Col md={2} className="mt-4">
                                            {
                                                popupType != "formula" && values.grouptype?.value == "Advanced_compute" ?
                                                    <span className="add-value" onClick={() => addValuesComponent("")}>Add Condition</span>
                                                    :
                                                    popupType == "" ?
                                                        <div >
                                                            <span className="add-value" onClick={() => addValuesComponent("")}>Add Condition</span>
                                                            {/* <span className="add-value" onClick={() => addDefaultInput()}>Add Default</span> */}
                                                        </div>
                                                        : ""
                                            }
                                        </Col>
                                    </Row>
                                </div>

                                <div>
                                    {
                                        variableList && variableList.length > 0 ?
                                            <CustomButton type="primary" className='save-button' text="Save" onClick={() => saveData()}></CustomButton>
                                            : ""}
                                </div>
                            </div>
                    }


                </div>
            </div>

        </>
    )
}