import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setLoading } from "../../store/slices/auth";
import { deleteAward, deleteEducation, deleteResearchExperience, deleteResearchProfile, deleteResearchWork, deleteSkillSet, deleteWorkExperience, getProfileDetails } from "../profile/apiController";
import { Card, CardBody, CardHeader, Col, Collapse, Row } from 'reactstrap';
import { DownOutlined, LeftOutlined, LinkOutlined, TrophyFilled, UpOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { expectedDateFormat } from "../../utils/Utils";
import ContactDetails from "../profile/ContactDetails";
import ProfileDetails from "../profile/ProfileDetails";
import AddressDetails from "../profile/AddressDetails";
import SkillSet from "../profile/SkillSet";
import WorkExperience from "../profile/WorkExperience";
import EducationDetails from "../profile/EducationDetails";
import Awards from "../profile/Awards";
import ResearchWork from "../profile/ResearchWork";
import PublicationDetails from "../profile/PublicationDetails";
import ResearchProfile from "../profile/ResearchProfile";
import dayjs from 'dayjs';
import { showToaster, toasterTypes } from "../../widgets";

export default function ProfileMobile(props) {
    const dispatch = useDispatch();
    const [profile, setprofile] = useState<any | null>(null);
    const [popupType, setpopupType] = useState('');
    const [selectedIndex, setselectedIndex] = useState(null);
    const navigate = useNavigate();
    const [isOpen, SetOpen] = useState(false);
    const [isOpenAdd, SetOpenAdd] = useState(false);
    const [isOpenContact, SetOpenContact] = useState(false);
    const [isOpenSkill, SetOpenSkill] = useState(false);
    const [isOpenWork, SetOpenWork] = useState(false);
    const [isOpenEdu, SetOpenEdu] = useState(false);
    const [isOpenAward, SetOpenAward] = useState(false);
    const [isOpenResearch, SetOpenResearch] = useState(false);

    useEffect(() => {
        fetchProfileDetails();
    }, []);

    /**
     * fetch user information
     */
    const fetchProfileDetails = async () => {
        try {
            dispatch(setLoading(true));
            let response = await getProfileDetails({ userid: "" });
            setprofile(response);
            dispatch(setLoading(false));
        } catch (e: any) {
            dispatch(setLoading(false));
        }
    }
    /**
    * 
    * @param type 
    */
    const closePopup = (type) => {
        if (type === 'callAPI') {
            fetchProfileDetails();
        }
        setselectedIndex(null);
        setpopupType('');
    }

    const deleteSkill = async (data) => {
		try {
			dispatch(setLoading(true));
			let response = await deleteSkillSet(data.id);
			showToaster(toasterTypes.SUCCESS, response.message);
			fetchProfileDetails();
			dispatch(setLoading(false));
		} catch (e: any) {
			dispatch(setLoading(false));
		}
	}

    const deleteWork = async (data) => {
		try {
			dispatch(setLoading(true));
			let response = await deleteWorkExperience(data.id);
			showToaster(toasterTypes.SUCCESS, response.message);
			fetchProfileDetails();
			dispatch(setLoading(false));
		} catch (e: any) {
			dispatch(setLoading(false));
		}
	}

	const deleteEducationData = async (data) => {
		try {
			dispatch(setLoading(true));
			let response = await deleteEducation(data.id);
			showToaster(toasterTypes.SUCCESS, response.message);
			fetchProfileDetails();
			dispatch(setLoading(false));
		} catch (e: any) {
			dispatch(setLoading(false));
		}
	}

	const deleteAwardData = async (data) => {
		try {
			dispatch(setLoading(true));
			let response = await deleteAward(data.id);
			showToaster(toasterTypes.SUCCESS, response.message);
			fetchProfileDetails();
			dispatch(setLoading(false));
		} catch (e: any) {
			dispatch(setLoading(false));
		}
	}

	const deleteResearchProfileData = async (data) => {
		try {
			dispatch(setLoading(true));
			let response = await deleteResearchProfile(data.id);
			showToaster(toasterTypes.SUCCESS, response.message);
			fetchProfileDetails();
			dispatch(setLoading(false));
		} catch (e: any) {
			dispatch(setLoading(false));
		}
	}

	const deleteResearchExperienceData = async (data) => {
		try {
			dispatch(setLoading(true));
			let response = await deleteResearchExperience(data);
			showToaster(toasterTypes.SUCCESS, response.message);
			fetchProfileDetails();
			dispatch(setLoading(false));
		} catch (e: any) {
			dispatch(setLoading(false));
		}
	}
	/**
	 * delete research work
	 */
	const deleteResearchWorkata = async (data) => {
		try {
			dispatch(setLoading(true));
			let response = await deleteResearchWork(data.id);
			showToaster(toasterTypes.SUCCESS, response.message);
			fetchProfileDetails();
			dispatch(setLoading(false));
		} catch (e: any) {
			dispatch(setLoading(false));
		}
	}

    return (
        <>
            {popupType === 'profile' ?
                <ProfileDetails {...props} data={profile} userNumber={profile.usernumber} onClose={(type) => closePopup(type)} />
                : popupType === 'contact' ?
                    <ContactDetails {...props} data={profile.contact_details} userNumber={profile.usernumber} onClose={(type) => closePopup(type)} />
                    : popupType === 'address' ?
                        <AddressDetails {...props} data={profile.address_details} selectedIndex={selectedIndex} userNumber={profile.usernumber} onClose={(type) => closePopup(type)} />
                        : popupType === 'skillSet' ?
                            <SkillSet {...props} data={profile} selectedIndex={selectedIndex} userNumber={profile.usernumber} onClose={(type) => closePopup(type)} />
                            : popupType === 'work' ?
                                <WorkExperience {...props} data={profile.work_details} selectedIndex={selectedIndex} userNumber={profile.usernumber} onAdd={() => { setselectedIndex(null); setpopupType('skillSet'); }} onClose={(type) => closePopup(type)} />
                                : popupType === 'education' ?
                                    <EducationDetails {...props} data={profile.education_details} selectedIndex={selectedIndex} userNumber={profile.usernumber} onClose={(type) => closePopup(type)} />
                                    : popupType === 'awards' ?
                                        <Awards {...props} data={profile.award_details} selectedIndex={selectedIndex} userNumber={profile.usernumber} onClose={(type) => closePopup(type)} />
                                        : popupType === 'research' ?
                                            <ResearchProfile {...props} selectedIndex={selectedIndex} data={profile.research_profiles} userNumber={profile.usernumber} onClose={(type) => closePopup(type)} />
                                            : popupType === 'publication' ?
                                                <PublicationDetails {...props} selectedIndex={0} data={profile} userNumber={profile.usernumber} onClose={(type) => closePopup(type)} />
                                                : popupType === 'researchwork' ?
                                                    <ResearchWork {...props} selectedIndex={selectedIndex} data={profile} userNumber={profile.usernumber} onClose={(type) => closePopup(type)} />
                                                    : null

            }
            <div className="profile-screen-mobile">
                <div className="info-bar" onClick={() => navigate("/projectmobile")}>
                    <LeftOutlined className="arrow-back" />
                    <span className="info-project-detail"> Profile </span>
                </div>

                {profile &&
                    <>
                        <div className='page-scroll'>
                            <div className='card-container'>
                                <Row className='m-0 mb-3'>
                                    <Col className='p-0 d-flex m-0'>
                                        <div className='profile-data-mobile'>
                                            {profile?.photo_link ?
                                                <div className='profile-container'>
                                                    <img className='profile-image' alt='Profile' src={profile.photo_link} /> </div> :
                                                <div className='profile-container'>
                                                    <img className='profile-image' alt='Profile' src={`${process.env.PUBLIC_URL}/images/logo.png`} />
                                                </div>
                                            }
                                        </div>
                                        <div className='first-text'>
                                            <div className="name">{profile?.profile_title}{profile?.first_name}</div>
                                        </div>
                                    </Col>

                                    <Col className='p-0 d-flex justify-content-end'>
                                    <i className='icon-edit' onClick={() => setpopupType('profile')}></i>
                                    </Col>
                                </Row>
                            </div>

                            <div className='card-container'>
                                <Row className='m-0 mb-3'>
                                    <Col className='p-0'>
                                        <div className='card-header-text' onClick={() => SetOpenContact(!isOpenContact)}>{!isOpenContact ? <DownOutlined className="collapse-icon"/> : <UpOutlined className="collapse-icon" />}Contact Details</div>
                                    </Col>
                                    <Col className='p-0'>
                                        {Object.keys(profile?.contact_details).length === 0 ?
                                            <div className='d-flex m-0 justify-content-end' onClick={() => setpopupType('contact')}>
                                                <div className='add-text c-pointer'><i className='icon-add c-pointer'></i></div>
                                            </div> :
                                            <div className='d-flex m-0 justify-content-end' onClick={() => setpopupType('contact')}>
                                                <i className='icon-edit c-pointer'></i>
                                            </div>
                                        }
                                    </Col>
                                </Row>
                                <Collapse isOpen={isOpenContact}>
                                    {Object.keys(profile?.contact_details).length !== 0 &&
                                        <div className='contact-details'>


                                            <span className="head-text">Email</span>
                                            <div>
                                                <i className='icon-email'></i>
                                                <span className='profile-data'>{profile?.contact_details?.email}</span>
                                            </div>
                                            {profile?.contact_details?.work_email &&
                                                <div className='mt-2'>
                                                    <i className='icon-email'></i>
                                                    <span className='profile-data'>{profile?.contact_details?.work_email}</span>
                                                </div>
                                            }
                                            <hr></hr>
                                            <span className="head-text">Phone</span>
                                            <div>
                                                <i className='icon-phone'></i>
                                                <span className='profile-data'>{profile?.contact_details?.phone && profile?.contact_details?.phone.replace(" ", "-")}</span>
                                            </div>
                                            {profile?.contact_details?.work_phone && profile?.contact_details?.work_phone.replace(" ","").length > 0 ?
                                                <div>
                                                    <i className='icon-phone'></i>
                                                    <span className='profile-data'>{profile?.contact_details?.work_phone && profile?.contact_details?.work_phone.replace(" ", "-")}</span>
                                                </div>
                                                :
                                                ""
                                            }
                                        </div>
                                    }
                                </Collapse>

                            </div>

                            <div className='card-container'>
                                <Row className='m-0 mb-3'>
                                    <Col className='p-0'>
                                        <div className='card-header-text' onClick={() => SetOpenAdd(!isOpenAdd)}>{!isOpenAdd ? <DownOutlined className="collapse-icon" /> : <UpOutlined className="collapse-icon" />}Address</div>
                                    </Col>
                                    <Col className='p-0' xs={3}>

                                        {profile && profile?.address_details?.length == 0 || !profile?.address_details ?
                                            <div className='d-flex m-0 justify-content-end' onClick={() => setpopupType('address')}>
                                                <i className='icon-add c-pointer'></i>
                                                {/* <div className='add-text c-pointer'>ADD SKILL</div> */}
                                            </div>
                                            :""}
                                    </Col>
                                </Row>
                                <Collapse isOpen={isOpenAdd}>
                                    {profile?.address_details?.map((obj, index) => (
                                        <>
                                            <Row className='m-0 mb-3'>
                                                {/* <Col className='p-0' >
                                                    <div className='address-type'>{obj.address_type + " Address"}</div>
                                                </Col> */}
                                                <Col className='p-0'>
                                                    {Object.keys(profile?.address_details).length == 0 ?
                                                        <div className='d-flex m-0 justify-content-end'>
                                                            <div className='add-text c-pointer' onClick={() => { setpopupType('address'); }}>
                                                                <i className='icon-add c-pointer'></i>
                                                            </div>
                                                        </div> :
                                                        <div className='d-flex m-0 justify-content-end' onClick={() => { setselectedIndex(index); setpopupType('address'); }}>
                                                            <i className='icon-edit c-pointer'></i>
                                                        </div>
                                                    }
                                                </Col>
                                            </Row>
                                            <Row key={index} className='m-0 mb-3'>

                                                <Col className='p-0'>

                                                    <div className='address-text'>
                                                        <span className='label-text'>Pincode</span>
                                                        <span className='pin-text'>{obj.pincode} </span>
                                                        <span className='label-text'>Address</span>
                                                        <span className='pin-text'>{obj.address_text}, {obj.city}, {obj.state}, {obj.country}</span>
                                                    </div>
                                                    {index == 0 ? <hr></hr> : ""}

                                                </Col>
                                            </Row>
                                        </>
                                    ))}
                                </Collapse>
                            </div>

                            <div className='card-container'>
                                <Row className='m-0 mb-3'>
                                    <Col className='p-1' xs={9}>
                                        <div className='card-header-text' onClick={() => SetOpenSkill(!isOpenSkill)}>{!isOpenSkill ? <DownOutlined className="collapse-icon" /> : <UpOutlined className="collapse-icon" />}Skill Set & Certifications</div>
                                    </Col>
                                    <Col className='p-0' xs={3}>
                                        <div className='d-flex m-0 justify-content-end' onClick={() => setpopupType('skillSet')}>
                                            <i className='icon-add c-pointer'></i>
                                            {/* <div className='add-text c-pointer'>ADD SKILL</div> */}
                                        </div>
                                    </Col>
                                </Row>
                                <Collapse isOpen={isOpenSkill}>
                                    {profile && profile?.skills?.map((obj, index) => (
                                        <>
                                            <Row key={index} className='m-0 mt-2 skill-container'>
                                                <Col md={12} className='p-0'>
                                                    <div className='skill-data'>
                                                        <div className='logo-border'>
                                                            <img src={`${process.env.PUBLIC_URL}/images/icon_skill.svg`} alt='logo' className='logo' />
                                                        </div>
                                                        <div>
                                                            <div className='skill-name'>{obj.skill_name}</div>
                                                            <div className='skill-rating'>Skill rating <span className='skill-value'>{obj.self_rating}</span><span className='total-rating'> / 5</span></div>
                                                        </div>
                                                    </div>
                                                </Col>

                                                <Row className='m-0'>
                                                    {obj.certifications && (
                                                        obj?.certifications.map((subObj, subIndex) => (

                                                            <div className='pl-0'>
                                                                <div className='certification-name'>{subObj.certification_name}</div>
                                                                {subObj.certification_url ?<div className='link-text' onClick={() => window.open(subObj.certification_url)}><LinkOutlined/> {subObj.certification_url.replace(" ", "")}</div> : ""}
                                                                <div className='certification-institute'>{subObj.issued_organization}</div>
                                                                <div className='certification-date'>{subObj.issued_month ? "Issued : " + subObj.issued_month.match(/[a-zA-Z]/g) ? subObj.issued_month + " " + subObj.issued_year : moment(subObj.issued_month + "/" + subObj.issued_year, "DD MM yyyy").format('DD-MMM-YYYY') : ""}</div>
                                                            </div>

                                                        ))
                                                    )}
                                                    {(!obj.certifications || obj.certifications.length === 0) &&
                                                        <div className='no-text text-center w-100 pt-3'>No Certifications<span className='add-text' onClick={() => { setpopupType('skillSet'); setselectedIndex(index) }}>Add Certificate</span></div>
                                                    }
                                                </Row>
                                            </Row>

                                            <Row className="action-item mt-3 pt-2">
                                                <Col xs={1}>
                                                    <i className='icon-edit c-pointer mr-5 action' onClick={() => { setpopupType('skillSet'); setselectedIndex(index) }}></i>
                                                </Col>
                                                <Col xs={2}>
                                                    <div className="seperator"></div>
                                                </Col>
                                                <Col xs={5}>
                                                    <i className='icon-delete c-pointer' onClick={() => deleteSkill(obj)}></i>
                                                </Col>
                                            </Row>
                                            {profile?.skills?.length != index + 1 ? <hr></hr> : ""}
                                        </>
                                    ))}
                                </Collapse>
                            </div>

                            <div className='card-container'>
                                <Row className='m-0 mb-3'>
                                    <Col className='p-1' xs={9}>
                                        <div className='card-header-text' onClick={() => SetOpenWork(!isOpenWork)}>{!isOpenWork ? <DownOutlined className="collapse-icon" /> : <UpOutlined className="collapse-icon" />}Work Experience</div>
                                    </Col>
                                    <Col className='p-0' xs={3}>
                                        <div className='d-flex m-0 justify-content-end' onClick={() => setpopupType('work')}>
                                            <i className='icon-add c-pointer'></i>
                                            {/* <div className='add-text c-pointer'>ADD SKILL</div> */}
                                        </div>
                                    </Col>
                                </Row>
                                <Collapse isOpen={isOpenWork}>
                                    {profile && profile?.work_details?.map((obj, index) => (
                                        <>
                                            <Row key={index} className='m-0 mt-2 skill-container'>
                                                <Col md={4} className='p-0'>
                                                    <div className='skill-data'>
                                                        <div className='logo-border'>
                                                            <img src={`${process.env.PUBLIC_URL}/images/icon_skill.svg`} alt='logo' className='logo' />
                                                        </div>
                                                        <div>
                                                            <div className='skill-name'>{obj.company_name}, <span className='job-title'>{obj.job_title}</span></div>
                                                            {/* <div className='total-rating'>{obj.job_description}</div> */}
                                                            <div className='skill-rating'>{moment(obj.start_month + ' ' + obj.start_year).format('DD MMM yyyy') != "Invalid date" ? obj?.start_month && obj.start_month.match(/[a-zA-Z]/g) ? obj.start_month + obj.start_year : moment(obj.start_month + '/' + obj.start_year, "DD/MM/yyyy").format('DD MMM yyyy') : "" || moment(obj.start_month + '/' + obj.start_year, "DD/MM/YYYY").format('DD MMM yyyy')} {" to "}
                                                                {obj.end_month === 'till_date' ? 'Till Date' : obj.end_month.match(/[a-zA-Z]/g) ? obj.end_month + obj.end_year : moment(obj.end_month + '/' + obj.end_year, "DD/MM/YYYY").format('DD MMM yyyy')}</div>
                                                            <div className='skill-rating'>{obj?.location?.city}, {obj?.location?.state}, {obj?.location?.country}</div>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col md={6} className='p-0'>
                                                    <div className='skill-data flex-column align-items-start'>
                                                        <div className='skill-desc-head'>Skills / Description / Activites / Links</div>
                                                        <div className='row m-0'>
                                                            {obj.skills?.map((subObj, subIndex) => (
                                                                <div className='activity-container' key={subIndex}>
                                                                    <div className='activity-text'>{subObj.skill_name}</div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                        <div className='total-rating'><a href={obj.documents} target="_blank">{obj.documents}</a></div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row className="action-item mt-3 pt-2">
                                                <Col xs={1}>
                                                    <i className='icon-edit c-pointer mr-5 action' onClick={() => { setpopupType('work'); setselectedIndex(index) }}></i>
                                                </Col>
                                                <Col xs={2}>
                                                    <div className="seperator"></div>
                                                </Col>
                                                <Col xs={5}>
                                                    <i className='icon-delete c-pointer' onClick={() => deleteWork(obj)}></i>
                                                </Col>
                                            </Row>
                                            {profile?.work_details?.length != index + 1 ? <hr></hr> : ""}
                                        </>

                                    ))}
                                </Collapse>

                            </div>

                            <div className='card-container'>
                                <Row className='m-0 mb-3'>
                                    <Col className='p-0'>
                                        <div className='card-header-text' onClick={() => SetOpenEdu(!isOpenEdu)}>{!isOpenEdu ? <DownOutlined className="collapse-icon" /> : <UpOutlined className="collapse-icon" />}Education</div>
                                    </Col>
                                    <Col className='p-0'>
                                        <div className='d-flex m-0 justify-content-end' onClick={() => setpopupType('education')}>
                                            <i className='icon-add c-pointer'></i>
                                            {/* <div className='add-text c-pointer'>ADD SKILL</div> */}
                                        </div>
                                    </Col>
                                </Row>
                                <Collapse isOpen={isOpenEdu}>
                                    {profile?.education_details?.map((obj, index) => (
                                        <>
                                            <Row key={index} className='m-0 mt-2 skill-container'>
                                                <Col md={4} className='p-0'>
                                                    <div className='skill-data'>
                                                        <div className='logo-border'>
                                                            <img src={`${process.env.PUBLIC_URL}/images/icon_skill.svg`} alt='logo' className='logo' />
                                                        </div>
                                                        <div>
                                                            <div className='skill-name'>{obj.institute}</div>
                                                            <div className='total-rating'>{obj.degree} in {obj.field_of_study}</div>
                                                            <div className='total-rating'>{obj.description}</div>
                                                            <div className='skill-rating'>{moment(obj.start_month + ' ' + obj.start_year).format('DD MMM yyyy') != "Invalid date" ? obj.start_month && obj.start_month.match(/[a-zA-Z]/g) ? obj.start_month + ' ' + obj.start_year : moment(obj.start_month + '/' + obj.start_year, "DD/MM/yyyy").format('DD MMM yyyy') : "" || obj.start_month && obj.start_month.match(/[a-zA-Z]/g) ? obj.start_month + ' ' + obj.start_year : moment(obj.start_month + '/' + obj.start_year, "DD/MM/YYYY").format('DD MMM yyyy')}
                                                                {" to "}{obj.end_month === 'till_date' ? 'Till Date' : obj.end_month && obj.end_month.match(/[a-zA-Z]/g) ? obj.end_month + ' ' + obj.end_year : moment(obj.end_month + '/' + obj.end_year, "DD/MM/YYYY").format('DD MMM yyyy')}</div>  <div className='skill-rating'>{obj?.location?.city}, {obj?.location?.state}, {obj?.location?.country}</div>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col md={6} className='p-0'>
                                                    <div className='skill-data flex-column align-items-start'>
                                                        <div className='skill-desc-head'>Skills / Description / Activites / Links</div>
                                                        <div className='row m-0'>
                                                            {obj.activities.map((subObj, subIndex) => (
                                                                <div className='activity-container' key={subIndex}>
                                                                    <div className='activity-text'>{subObj}</div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                        <div className='total-rating'>{obj.documents}</div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row className="action-item mt-3 pt-2">
                                                <Col xs={1}>
                                                    <i className='icon-edit c-pointer mr-5 action' onClick={() => { setpopupType('education'); setselectedIndex(index) }}></i>
                                                </Col>
                                                <Col xs={2}>
                                                    <div className="seperator"></div>
                                                </Col>
                                                <Col xs={5}>
                                                    <i className='icon-delete c-pointer' onClick={() => deleteEducation(obj)}></i>
                                                </Col>
                                            </Row>
                                            {profile?.education_details?.length != index + 1 ? <hr></hr> : ""}
                                        </>
                                    ))}
                                </Collapse>
                            </div>

                            <div className='card-container'>
                                <Row className='m-0 mb-3'>
                                    <Col className='p-1'>
                                        <div className='card-header-text' onClick={() => SetOpenAward(!isOpenAward)}>{!isOpenAward ? <DownOutlined className="collapse-icon" /> : <UpOutlined className="collapse-icon" />}Awards</div>
                                    </Col>
                                    <Col className='p-0'>
                                        <div className='d-flex m-0 justify-content-end' onClick={() => setpopupType('awards')}>
                                            <i className='icon-add c-pointer'></i>
                                            {/* <div className='add-text c-pointer'>ADD SKILL</div> */}
                                        </div>
                                    </Col>
                                </Row>
                                <Collapse isOpen={isOpenAward}>
                                    {profile?.award_details?.map((obj, index) => (
                                        <>
                                            <Row key={index} className='m-0 mt-2 skill-container'>
                                                <Card className="award-card">
                                                    <CardHeader className="award-card-head" >
                                                        <Row>
                                                            <Col xs={10}>{obj.award_name}</Col>
                                                            <Col xs={2}><TrophyFilled className="trophy" /></Col>
                                                        </Row>
                                                    </CardHeader>
                                                    <CardBody>
                                                        {obj.award_url && <div className='link-text' onClick={() => window.open(obj.award_url)}>{obj.award_url}</div>}
                                                        <div className='total-rating'>{obj.award_description}</div>
                                                    </CardBody>
                                                </Card>
                                            </Row>
                                            <Row className="action-item mt-3 pt-2">
                                                <Col xs={1}>
                                                    <i className='icon-edit c-pointer mr-5 action' onClick={() => { setpopupType('awards'); setselectedIndex(index) }}></i>
                                                </Col>
                                                <Col xs={2}>
                                                    <div className="seperator"></div>
                                                </Col>
                                                <Col xs={5}>
                                                    <i className='icon-delete c-pointer' onClick={() => deleteAward(obj)}></i>
                                                </Col>
                                            </Row>
                                            {profile?.award_details?.length != index + 1 ? <hr></hr> : ""}
                                        </>
                                    ))}
                                </Collapse>
                            </div>

                            <div className='card-container'>
                                <Row className='m-0 mb-3'>
                                    <Col className='p-1' xs={9}>
                                        <div className='card-header-text' onClick={() => SetOpenResearch(!isOpenResearch)}>{!isOpenResearch ? <DownOutlined className="collapse-icon" /> : <UpOutlined className="collapse-icon" />}Research Profiles</div>
                                    </Col>
                                    <Col className='p-0' xs={3}>
                                        <div className='d-flex m-0 justify-content-end' onClick={() => setpopupType('publication')}>
                                            {profile.research_experience ?
                                                <i className='icon-edit c-pointer'></i>
                                                :
                                                <i className='icon-add c-pointer'></i>
                                            }
                                        </div>
                                    </Col>
                                </Row>
                                <Collapse isOpen={isOpenResearch}>
                                    <Card className="card-research-profile">
                                        <CardHeader className="research-head">
                                            <Row>

                                                {/* <Col xs={4}>
                                                    Orcid ID  {profile.research_experience.orcid}
                                                </Col> */}
                                                <Col xs={6}>
                                                    H Index  {profile.research_experience.h_index}
                                                </Col>
                                                <Col xs={6}>
                                                    I 10 Index  {profile.research_experience.i10_index}
                                                </Col>
                                            </Row>
                                        </CardHeader>
                                        <CardBody className="research-body">
                                            <Row>
                                                <Col xs={4}>
                                                    Patents  {profile.research_experience.patent_count}</Col>
                                                <Col xs={4}>
                                                    Publications {profile.research_experience.publication_count}</Col>
                                                <Col xs={4}>
                                                    Projects  {profile.research_experience.projects_count}</Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                    <hr></hr>

                                    <Row className='m-0 mb-3'>
                                        <Col className='p-1'>
                                            <div className='card-header-text'>Profile Links</div>
                                        </Col>
                                        <Col className='p-0'>
                                            <div className='d-flex m-0 justify-content-end' onClick={() => setpopupType('research')}>
                                                <i className='icon-add c-pointer'></i>
                                            </div>
                                        </Col>
                                    </Row>
                                    {profile?.research_profiles?.map((obj, index) => (
                                        <>
                                            <Row key={index} className='m-0 mt-2 skill-container'>
                                                <Col md={10} className='p-0'>
                                                    <div className='skill-data-profile'>
                                                        <div className='logo-border'>
                                                            <img src={`${process.env.PUBLIC_URL}/images/icon_skill.svg`} alt='logo' className='logo' />
                                                        </div>
                                                        <div>
                                                            <div className='skill-name'>{obj.profile_type}</div>
                                                            {obj.profile_link && <div className='link-text' onClick={() => window.open(obj.award_url)}>{obj.profile_link}</div>}
                                                        </div>
                                                    </div>
                                                </Col>

                                            </Row>
                                            <Row className="action-item mt-3 pt-2">
                                                <Col xs={1}>
                                                    <i className='icon-edit c-pointer mr-5 action' onClick={() => { setpopupType('research'); setselectedIndex(index) }}></i>
                                                </Col>
                                                <Col xs={2}>
                                                    <div className="seperator"></div>
                                                </Col>
                                                <Col xs={5}>
                                                    <i className='icon-delete c-pointer' onClick={() => deleteResearchProfileData(obj)}></i>
                                                </Col>
                                            </Row>
                                            {profile?.research_profiles?.length != index + 1 ? <hr></hr> : ""}
                                        </>
                                    ))}
                                </Collapse>
                            </div>

                            <div className='card-container'>
                                <Row className='m-0 mb-3'>
                                    <Col className='p-1' xs={9}>
                                        <div className='card-header-text' onClick={() => SetOpen(!isOpen)}>{!isOpen ? <DownOutlined className="collapse-icon" /> : <UpOutlined className="collapse-icon" />}Research Works</div>
                                    </Col>
                                    <Col className='p-0' xs={3}>
                                        <div className='d-flex m-0 justify-content-end' onClick={() => { setpopupType('researchwork'); }}>
                                            <i className='icon-add c-pointer'></i>
                                        </div>
                                    </Col>
                                </Row>
                                <Collapse isOpen={isOpen}>
                                    {profile?.research_project?.map((obj, index) => (
                                        <>
                                            <Row key={0} className='m-0 mt-2 research-work-container'>
                                                <Col md={10} className='p-0'>
                                                    <div className='skill-data'>
                                                        <div>
                                                            <div className='project-name'>
                                                                <span>{obj.project_name}</span>
                                                                <span style={{ color: "#2F2E40", fontSize: "14px" }}> - </span>
                                                                <span className="pub-type">{obj.publication_type?.label}</span>
                                                            </div>
                                                            {obj.authors && <div className='project-descp'><span style={{ color: "#ccc", fontSize: "14px" }}>Authors:</span> {obj.authors.map((item, index) => {
                                                                return <span>{item}{index + 1 != obj.authors.length ? ", " : ""} </span>
                                                            })}

                                                            
                                                            <div className="other-detail">
                                                                {obj.journal ? <span><span style={{ color: "#ccc", fontSize: "14px" }}>Journal Name: </span>{obj.journal} <span style={{ color: "#ccc", fontSize: "14px" }}> | </span></span> : ""}
                                                                {obj.publisher_name ? <span><span style={{ color: "#ccc", fontSize: "14px" }}>Published By: </span>{obj.publisher_name} <span style={{ color: "#ccc", fontSize: "14px" }}> | </span> </span> : ""}
                                                                {obj?.published_date ? <span><span style={{ color: "#ccc", fontSize: "14px" }}>Year: </span>{dayjs(obj?.published_date, "YYYY-MM-DD HH:mm:ss").format("YYYY")}<span style={{ color: "#ccc", fontSize: "14px" }}> | </span></span> : ""}
                                                                {obj.volume ? <span> <span style={{ color: "#ccc", fontSize: "14px" }}>Volume: </span>{obj.volume} <span style={{ color: "#ccc", fontSize: "14px" }}> | </span></span> : ""}
                                                                {obj.issue ? <span> <span style={{ color: "#ccc", fontSize: "14px" }}>Issue: </span>{obj.issue} <span style={{ color: "#ccc", fontSize: "14px" }}> | </span></span> : ""}
                                                                {obj.page_no ? <span><span style={{ color: "#ccc", fontSize: "14px" }}>PageNo.: </span>{obj.page_no}<span style={{ color: "#ccc", fontSize: "14px" }}> | </span> </span> : ""}
                                                                {obj.place ? <span><span style={{ color: "#ccc", fontSize: "14px" }}>Place.: </span>{obj.place} </span> : ""}
                                                            </div>
                                                            {obj.doi ? <><span style={{ color: "#ccc", fontSize: "14px" }}> | </span> <span style={{ color: "#ccc", fontSize: "14px" }}>DOI:<a target="_blank" href={obj.doi}>{obj.doi}</a> </span> </> : ""}
                                                            </div>
                                                            }
                                                            {/* <div className='publish-date'>{obj?.published_date && expectedDateFormat(obj?.published_date, 'DD MMM YYYY', 'YYYY-MM-DD')}</div> */}
                                                            {obj.project_status.label == "Published" ?
                                                                <div><span className='project-status-completed'>{obj.project_status.label}</span>{obj.url ? <span><span style={{ color: "#ccc", fontSize: "14px" }}>| URL: </span><a target="_blank" href={obj.url}>{obj.url}</a></span> : ""}</div>
                                                                :
                                                                <div className='project-status-progress'>{obj.project_status.label}</div>
                                                            }

                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row className="action-item mt-3 pt-2">
                                                <Col xs={1}>
                                                    <i className='icon-edit c-pointer mr-5 action' onClick={() => { setpopupType('researchwork'); setselectedIndex(index) }}></i>
                                                </Col>
                                                <Col xs={2}>
                                                    <div className="seperator"></div>
                                                </Col>
                                                <Col xs={5}>
                                                    <i className='icon-delete c-pointer' onClick={() => deleteResearchWorkata(obj)}></i>
                                                </Col>
                                            </Row>
                                            {profile?.research_profiles?.length != index + 1 ? <hr></hr> : ""}
                                        </>
                                    ))
                                    }
                                </Collapse>
                            </div>
                        </div>
                    </>
                }
            </div>
        </>
    )
}